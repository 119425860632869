import s from "../styles/sociales.module.css"
import cx from "classnames"

export function Espacio({ imgRoute, nombre, tipo, px, hrs, hrsEng}) {
    return (
        <div className={ s.espacio }>
            <img className={ s.espacio_bg } src={ "/images/sociales/" + imgRoute + ".png"} srcset={ "/images/sociales/" + imgRoute + ".webp"} alt={ nombre }/>
            <div className={ s.espacio_des }>
                <div className={ s.espacio_des__t1 }>{ nombre }</div>
                <div className={ s.espacio_des__t2 }>- { tipo }</div>
                <div className={ s.espacio_des__t2 }>- CAPACIDAD { px } PX</div>
                { hrsEng != null && <div className={ s.espacio_des__t2 }>- { hrsEng } HOURS</div> }
                { hrs != null && <div className={ s.espacio_des__t2 }>- { hrs } HORAS</div> }
            </div>
        </div>
    )
}