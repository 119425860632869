import { Link } from "react-router-dom"
import ex from "../styles/exhibicionTipo.module.css"
import cx from "classnames"

export function CioDescription({ des }) {
    return (
        <div className="contenido_des">
            <div className="contenido_des__t1">Centro de Investigaciones Oceánicas del Mar de Cortés</div>
            <div className="contenido_des__sep"></div>
            {des.map((text, index) => (  
                <div key={ index } className="contenido_des__t2">{ text }</div>
            ))}
            {/*<div className="contenido_des__t2">Entra en el laboratorio del Centro de Investigaciones Oceánicas para que los biólogos te expliquen cómo se reproducen peces, medusas y de dónde sale el alimento vivo con el que se nutren los habitantes del acuario.</div>*/}
            <div className="contenido_des__btn"><a href="https://ciomc.org/" target="_blank">Conoce CIOMC</a></div>
        </div>
    )
}

export function CioDescriptionEng({ des }) {
    return (
        <div className="contenido_des">
            <div className="contenido_des__t1">The Sea of Cortez Oceanic Research Center</div>
            <div className="contenido_des__sep"></div>
            {des.map((text, index) => (  
                <div key={ index } className="contenido_des__t2">{ text }</div>
            ))}
            {/*<div className="contenido_des__t2">Entra en el laboratorio del Centro de Investigaciones Oceánicas para que los biólogos te expliquen cómo se reproducen peces, medusas y de dónde sale el alimento vivo con el que se nutren los habitantes del acuario.</div>*/}
            <div className="contenido_des__btn"><a href="https://ciomc.org/" target="_blank">About the SCORC</a></div>
        </div>
    )
}

export function Element({ link, nombre }) {
    return (
        <div className="contenido_ops__el tierra title">
            <Link to={ link }></Link>
            { nombre }
        </div>
    )
}

export function Especie({ route, exhibicion, habitat, nombre, nombreDisplay }) {
    const finalName = nombreDisplay ? nombreDisplay.replace('/', ' ') : nombre.replace('/', ' ');
    return (
        <div className={ex.habitat_con_el}>
            <Link to={ route }></Link>
            <img src={"/images/" + exhibicion + "/" + habitat + "/" + route + "/front.png"} srcSet={"/images/" + exhibicion + "/" + habitat + "/" + route + "/front.webp"} alt={ nombre } className={ex.habitat_con_el__img}/>
            <div className={ex.habitat_con_el__t1 + " title"}>{ finalName }</div>
        </div>
    )
}

export function EspecieEng({ route, carpet_route, exhibicion, habitat, nombre, nombreDisplay }) {
    const finalName = nombreDisplay ? nombreDisplay.replace('/', ' ') : nombre.replace('/', ' ');
    return (
        <div className={ex.habitat_con_el}>
            <Link to={ route }></Link>
            <img src={"/images/" + exhibicion + "/" + habitat + "/" + carpet_route + "/front.png"} srcSet={"/images/" + exhibicion + "/" + habitat + "/" + carpet_route + "/front.webp"} alt={ nombre } className={ex.habitat_con_el__img}/>
            <div className={ex.habitat_con_el__t1 + " title"}>{ finalName }</div>
        </div>
    )
}

export function Pregunta({ pregunta, respuesta }) {
    return (
        <div className={ cx(ex.preguntas_el, ex.des) }>
            <div className={ ex.preguntas_el__t1 }>{ pregunta }</div>
            <div className={ ex.preguntas_el__t2 }>{ respuesta }</div>
        </div>
    )
}