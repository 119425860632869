/*
    'preguntas': [
        {'pregunta': 'Pregunta', 'respuesta': 'Respuesta'},
        {'pregunta': 'Pregunta', 'respuesta': 'Respuesta'},
        {'pregunta': 'Pregunta', 'respuesta': 'Respuesta'},
    ],
    'especies': [
        {
            'nombre_comun':'',
            'nombre_display':'',
            'nombre_cientifico':'',
            'conservacion':'',
            'route':'',
            'datos': [
                '',
            ],
            'generalidades': [
                '',
            ],
        },
    ]
*/

/* HÁBITATS DE TIERRA */
export const TierraHabitats = [
    {"link": "terrario", "nombre": "TERRARIO"},
    {"link": "jardines", "nombre": "JARDINES"},
    /*{"link": "aviario", "nombre": "AVIARIO"},*/
];
export const TierraHabitatsEng = [
    {"carpeta": "terrario", "link":"terrarium", "nombre": "TERRARIUM"},
    {"carpeta": "jardines", "link":"gardens", "nombre": "GARDENS"},
    /*{"link": "aviario", "nombre": "AVIARIO"},*/
];

/* HÁBITATS DE COSTA */
export const CostaHabitats = [
    {"link": "intermareal", "nombre": "INTERMAREAL"},
    {"link": "manglar", "nombre": "MANGLAR"},
    {"link": "tortugas", "nombre": "TORTUGAS"},
    {"link": "bahia-rayas", "nombre": "BAHÍA DE LAS RAYAS"},
    {"link": "rompiente", "nombre": "ROMPIENTE"},
]
export const CostaHabitatsEng = [
    {"carpeta": "intermareal", "link":"intertidal", "nombre": "INTERTIDAL"},
    {"carpeta": "manglar", "link":"mangrove", "nombre": "MANGROVE"},
    {"carpeta": "tortugas", "link":"turtles", "nombre": "SEA TURTLES"},
    {"carpeta": "bahia-rayas", "link":"ray-bay", "nombre": "RAY BAY"},
    {"carpeta": "rompiente", "link":"surf-zone", "nombre": "SURF ZONE"},
]

/* HÁBITATS DE MAR */
export const MarHabitats = [
    {"link": "punto-pulmo", "nombre": "PUNTO PULMO"},
    {"link": "ciudad-coral", "nombre": "CIUDAD CORAL"},
    {"link": "establo-marino", "nombre": "ESTABLO MARINO"},
    {"link": "espejos", "nombre": "ESPEJOS"},
    {"link": "oceanica", "nombre": "OCEÁNICA"},
    {"link": "migracion", "nombre": "MIGRACIÓN"},
    {"link": "la-cueva", "nombre": "LA CUEVA"},
    {"link": "salon-de-las-medusas", "nombre": "SALÓN DE LAS MEDUSAS"},
    {"link": "cardumen", "nombre": "CARDÚMEN"},
    {"link": "la-ventana", "nombre": "LA VENTANA"},
];

export const MarHabitatsEng = [
    {"carpeta": "punto-pulmo", "link":"punto-pulmo", "nombre": "PUNTO PULMO"},
    {"carpeta": "ciudad-coral", "link":"coral-city", "nombre": "CORAL CITY"},
    {"carpeta": "establo-marino", "link":"sea-stable", "nombre": "SEA STABLE"},
    {"carpeta": "espejos", "link":"lookdowns", "nombre": "LOOKDOWNS"},
    {"carpeta": "oceanica", "link":"ocean", "nombre": "OCEAN"},
    {"carpeta": "migracion", "link":"migration", "nombre": "MIGRATION"},
    {"carpeta": "la-cueva", "link":"the-grotto", "nombre": "THE GROTTO"},
    {"carpeta": "salon-de-las-medusas", "link":"jellyfish-hall", "nombre": "JELLYFISH HALL"},
    {"carpeta": "cardumen", "link":"shoals", "nombre": "SHOALS"},
    {"carpeta": "la-ventana", "link":"the-window", "nombre": "THE WINDOW"},
];

export const habitats = [
    /* COSTA */
    { 
        /* INTERMAREAL */
        'id':'intermareal',
        'nombre':'INTERMAREAL',
        'habitat':'costa',
        'eng_carpet':'coast',
        'eng_link':'intertidal',
        'description':'Sumerge tus manos en un mundo de encuentro entre tierra y mar. Conoce a los integrantes del hábitat intermareal e interactúa con ellos ¡Descubre sus increíbles formas y texturas!',
        'preguntas': [
            {'pregunta':'¿Qué es la zona intermareal?', 'respuesta':'Es la zona de transición entre el ambiente terrestre y el mundo marino. Es una zona de gran dinamismo, que en cuestión de horas puede estar inundada o expuesta al aire.'},
            {'pregunta':'¿Quiénes viven en la zona intermareal?', 'respuesta':'Animales adaptados a ambientes cambiantes. Entre éstos se encuentran invertebrados como caracoles, cangrejos, estrellas de mar, erizos, anémonas y peces pequeños.'},
            {'pregunta':'¿Cuál es la importancia ecológica de estos lugares?', 'respuesta':'Proporciona alimento abundante a sus habitantes permanentes y temporales, como peces que llegan con la dinámica de las mareas y algunas aves. Además, son sitios de crianza para tortugas marinas, cangrejos y peces.'},
        ],
        'especies': [
            {
                'nombre_comun':'Borracho mono',
                'nombre_cientifico':'Ophioblennius steindachneri',
                'conservacion':'Preocupación menor',
                'route':'borracho-mono',
                'eng_route':'large-banded',
                'datos': [
                    'Le gusta vivir en las rocas, en la rompiente de las olas.',
                    'Son muy territoriales, defienden vigorosamente su hábitat de los intrusos.',
                    'Estos peces no tienen escamas.'
                ],
                'generalidades': [
                    'Cuerpo cilíndrico, cabeza con mechones (cirros) sobre sus ojos, nuca y en el borde de las fosas nasales.',
                    'Aletas inferiores alargadas.',
                    'Tiene los dientes incisivos en forma de peine para alimentarse de algas y de crustáceos.'
                ],
            },
            {
                'nombre_comun':'Estrella de mar chispas de chocolate',
                'nombre_display':'Estrella de mar/chispas de chocolate',
                'nombre_cientifico':'Nidorellia armata',
                'conservacion':'No evaluado',
                'route':'estrella',
                'eng_route':'chocolate-star',
                'datos': [
                    'La encontraron  como parte de la ofrenda en el Templo Mayor de Tenochtitlán (Cerca de la Ciudad de México).',
                    'El camarón  estrella de mar (Zenopontonia soror) suele asociarse a estrellas de mar como la chispas de chocolate: vive debajo ella, adopta el mismo color para camuflarse, le sirve como protección y se alimenta del moco que produce la estrella.',
                ],
                'generalidades': [
                    'Disco grueso e inflado de brazos cortos y gruesos, tiene espinas cónicas de color café oscuro que le dan su nombre común. Llega a medir hasta 15 cm de diámetro. Se alimenta de algas, otros equinodermos (erizos, pepinos) y pequeños invertebrados.',
                ],
            },
            {
                'nombre_comun':'Erizo punta de lápiz',
                'nombre_cientifico':'Eucidaris tribuloides',
                'conservacion':'No evaluado',
                'route':'erizo',
                'eng_route':'slate-pencil-urchin',
                'datos': [
                    'Cuentan con una compleja estructura para comer, la cual ha sido nombrada "Linterna de Aristóteles" en honor al sabio griego que la describió en su libro Historia animallum.',
                ],
                'generalidades': [
                    'El erizo punta de lápiz es un equinodermo: un animal sin huesos y de "piel espinosa", presenta exoesqueleto (esqueleto externo). Es de color café claro o rojizo y tiene pocas espinas gruesas, cafés y cilíndricas.',
                ],
            },
            {
                'nombre_comun':'Liebre marina',
                'nombre_cientifico':'Aplysia californica',
                'conservacion':'No evaluado',
                'route':'liebre-marina',
                'eng_route':'california-sea-hare',
                'datos': [
                    'Se le llama liebre de mar porque tiene dos tentáculos detrás de los ojos que se parecen las orejas de una liebre.',
                    'Puede liberar una tinta color púrpura intenso y con olor a cedro cuando se siente amenazada.'
                ],
                'generalidades': [
                    'Molusco de color variable, de café rojizo o verdoso, textura carnosa y delicada. En la cabeza tiene cuatro tentáculos y en el dorso dos pliegues en forma de alas y que cubren sus branquias. En la parte inferior del cuerpo tiene un pie musculoso que usa para moverse.',
                    'Los juveniles viven a unos 18m de la superficie, mientras que los adultos prefieren la zona intermareal.',
                    'Se alimenta de algas rojas, lechuga de mar y plantas marinas.'
                ],
            },
            {
                'nombre_comun':'Labrisómido Bocón, chalapo o trambollo',
                'nombre_display':'Labrisómido Bocón',
                'nombre_cientifico':'Labrisomus xanti',
                'conservacion':'Preocupación menor',
                'route':'labrisomido',
                'eng_route':'largemouth-blenny',
                'datos': [
                    'Debido al aumento de la temperatura del agua por el calentamiento global, está cambiando su distribución y ha  comenzado a aparecer hacia el norte, hasta California, en Estados Unidos.',
                    'Los peces labrisómidos como el bocón son pequeños y flexibles para moverse entre las rocas y encontrar refugio en conchas y grietas.',
                ],
                'generalidades': [
                    'Es de color café verdoso con barras ocuras a los lados, tiene una mancha negra en la aleta dorsal y manchitas blancas en cabeza y cuerpo. Sobre cada ojo y en la nuca tiene penachos (cirro) ramificados. Boca grande.',
                    'Vive en aguas someras y en rocas cubiertas por algas.',
                    'Se alimenta de crustáceos del suelo como pequeños cangrejos.',
                ],
            },
            {
                'nombre_comun':'Borrachito o blenio de sonora',
                'nombre_display':'Blenio de sonora',
                'nombre_cientifico':'Malacoctenus gigas',
                'conservacion':'Preocupación menor',
                'route':'blenio',
                'eng_route':'sonora-blenny',
                'datos': [
                    'Sólo se encuentra en las costas del Golfo de California, es decir, que es endémico de la zona.',
                    'Tolera temperaturas de hasta 10°C.',
                ],
                'generalidades': [
                    'Cabeza delgada, hocico puntiagudo y boca pequeña. Dorso color verde, pecho y vientre blancos, cuatro o cinco barras  de color café oscuro a los lados. Tienen un par de mechones (cirros) en la nuca y sobre los ojos.',
                    'En épocas de reproducción los machos tienen aletas oscuras, costados  anaranjado brillante con manchas azules azules.',
                    'Se alimenta de crustáceos pequeños.',
                    'Habita en aguas poco profundas, en arrecifes de coral y rocoso, entre algas marinas.',
                ],
            },
        ]
    },
    { 
        /* MANGLAR */
        'id':'manglar',
        'nombre':'MANGLAR',
        'habitat':'costa',
        'eng_carpet':'coast',
        'eng_link':'mangrove',
        'description':'Asómate a las raíces de nuestros manglares vivos. En el bosque anfibio habitan peces, crustáceos y muchos otros animales. El manglar nos cobija a todos y aquí te explicamos por qué.',
        'preguntas': [
            {'pregunta':'¿Qué son los manglares?', 'respuesta':'Son bosques anfibios de las zonas costeras tropicales, que crecen en áreas inundadas por las mareas. Están formados principalmente por árboles de mangle.'},
            {'pregunta':'¿Por qué son importantes los manglares?', 'respuesta':'En ellos interactúan distintas especies terrestres y acuáticas. Son sitios de reproducción y refugio de muchos animales en sus primeras etapas de vida. Además, proveen de nutrientes a ecosistemas marinos, filtran el agua y amortiguan el impacto de fenómenos meteorológicos como los huracanes.'},
            {'pregunta':'¿Qué especies de manglares podemos encontrar en el Mar de Cortés?', 'respuesta':'Mangle rojo (Rhizophora mangle), mangle blanco (Laguncularia racemosa), mangle prieto (Avicennia germinans) y Botoncillo (Conocarpus erectus).'},
        ],
        'especies': [
            {
                'nombre_comun':'Mangle negro o prieto, cenizo, puyeque, apompó, madre de sal',
                'nombre_display':"Mangle negro",
                'nombre_cientifico':'Avicennia germinans',
                'conservacion':'Preocupación menor',
                'route':'negro',
                'eng_route':'black-mangrove',
                'datos': [
                    'Es el mangle más tolerante al agua de mar.',
                    'Suele tener sus hojas cubiertas por polvo o cristales de sal porque tienen unas glándulas que eliminan el exceso de sal del agua que absorbe.'
                ],
                'generalidades': [
                    'A pesar de su nombre, es de color claro, tiene hojas opuestas de color gris pálido, brillante en la parte superior. Tiene unas raíces arqueadas que quedan expuestas cuando baja la marea y que emplean para respirar.',
                    'Sus flores son blancas o amarillas y florecen especialmente en época de lluvias.',
                    'Puede estar en aguas sin oxígeno, en zonas lodosas e inundadas.'
                ]
            },
            {
                'nombre_comun':'Mangle blanco',
                'nombre_cientifico':'Laguncularia racemosa',
                'conservacion':'Preocupación menor',
                'route':'blanco',
                'eng_route':'white-mangrove',
                'datos': [
                    'A veces es vivíparo, esto quiere decir que sus semillas germinan dentro del fruto mientras está aún en el árbol.',
                    'Su corteza tiene sustancias llamadas taninos que se emplean para curtir pieles.',
                    'Se ha usado como remedio tradicional para la disentería y problemas de salud por sus propiedades astringentes y tónicas.'
                ],
                'generalidades': [
                    'Arbusto de corteza fisurada, rugosa, grisácea-café. Sus hojas amarillo-verdosas son opuestas, redondeandas, carnosas, no tienen venas visibles  y tienen un par de protuberancias (glándulas).',
                    'Las flores blanco-verdosas se dan en espigas y aparecen de julio a octubre. Sus frutos tienen forma de almendra.',
                    'Habita en estuarios y ensenadas, en suelos de arena o arcilla poco inundables. Es susceptible al viento por sus raíces superficiales.'
                ]
            },
            {
                'nombre_comun':'Mangle rojo o colorado, candelón, mangle dulce o tinto',
                'nombre_display':'Mangle rojo',
                'nombre_cientifico':'Rhizophora mangle',
                'conservacion':'Preocupación menor',
                'route':'rojo',
                'eng_route':'red-mangrove',
                'datos': [
                    'A veces este mangle entabla una relación de mutuo beneficio con esponjas marinas que se instalan en sus raíces para intercambiar nutrientes.',
                    'Se usa como leña y para construir muebles e instrumentos. La corteza produce un tinte azul que se usa para teñir. También se emplea  como remedio tradicional para picadura de animales marinos, fiebre y otros problemas de salud.'
                ],
                'generalidades': [
                    'Árbol con raíces zancudas. Corteza lisa, grisácea, rojiza a pardo rojiza. Conserva sus hojas color verde oscuro todo el año.',
                    'Sus flores son amarillo verdosas a crema. Tiene un fruto en forma de baya color pardo rojiza. Dentro del fruto se forma una semilla que tiene forma de lanza y flota. El fruto crece con la semilla ya germinada en el árbol.',
                    'Le gusta vivir con los "pies en el agua", en sitios inundados todo el tiempo con una salinidad variable.'
                ]
            },
            {
                'nombre_comun':'Lenguado redondo, comal, tepalcate, sol de mazatlán',
                'nombre_display':'Lenguado redondo',
                'nombre_cientifico':'Achirus mazatlanus',
                'conservacion':'Preocupación menor',
                'route':'lenguado',
                'eng_route':'mazatlan-sole',
                'datos': [
                    'La palabra Achirus significa sin manos y mazatlanus se debe probablemente al sitio (Mazatlán) donde se capturó el especimen descrito por el zoólogo austriaco Franz Steindachner, quien lo describió en 1869.'
                ],
                'generalidades': [
                    'Cuerpo ovalado y los dos ojos del lado derecho, boca pequeña y arqueada. Es de color gris oscuro o parduzco. Tiene 8 líneas oscuras en el costado de los ojos y grupos dispersos de filamentos negros.',
                    'Habita en aguas costeras a menos de 20m de profundidad, entra a lagunas costeras y en agua dulce.',
                    'Se alimenta de crustáceos, peces pequeños, gusanos poliquetos y residuos orgánicos'
                ]
            },
            {
                'nombre_comun':'Camarón blanco del Pacífico',
                'nombre_display':'Camarón blanco/del Pacífico',
                'nombre_cientifico':'Litopaneaus vannamei',
                'conservacion':'No evaluado',
                'route':'camaron',
                'eng_route':'whiteleg-shrimp',
                'datos': [
                    'Es la especie de cultivo de camarón dominante en el mundo.',
                    'Tiene un par de glándulas en las antenas que tienen una función excretora tal que se podría decir  que "orinan" por las antenas.'
                ],
                'generalidades': [
                    'Es translúcido, azulado u oliva con bandas oscuras, café rojizo en las anténulas, pero se distingue por sus 10 patas  blancas.',
                    'Su cuerpo es alargado y segmentado, cubierto con un exoesqueleto. Tiene trece pares de apéndices (como antenas).',
                    'Crustáceo que se alimenta de plancton y residuos orgánicos.'
                ]
            },
            {
                'nombre_comun':'Constantino, robalo aleta amarilla',
                'nombre_display':'Constantino',
                'nombre_cientifico':'Centropomus robalito',
                'conservacion':'Preocupación menor',
                'route':'constantino',
                'eng_route':'common-snook',
                'datos': [
                    'De las 12 especies del género Centropomus que existen en el mundo todas se encuentran en México: 6 en el Atlántico y 6 en el Pacífico.',
                    'En el Suroeste del Golfo de California les gusta  alimentarse principalmente de camarón.'
                ],
                'generalidades': [
                    'Pez plateado con una notoria línea lateral oscura, cuerpo alargado y comprimido, aletas amarillas anales y pélvicas.',
                    'Habita bahías, estuarios y arroyos de agua dulce.',
                    'Se alimenta de peces, crustáceos y moluscos.'
                ]
            },
        ]
    },
    { 
        /* TORTUGAS */
        'id':'tortugas',
        'nombre':'TORTUGAS',
        'habitat':'costa',
        'eng_carpet':'coast',
        'eng_link':'turtles',
        'description':'Visita el refugio de tortugas, donde brindamos hogar temporal para la protección y recuperación de estos nobles reptiles.',
        'preguntas': [
            {'pregunta':'¿Qué son las tortugas?', 'respuesta':'Son reptiles que han habitado en la tierra desde hace más de 100 millones de años. Cuentan con grandes aletas y un caparazón estilizado que les ayuda a nadar rápidamente.'},
            {'pregunta':'¿De qué se alimentan las tortugas?', 'respuesta':'Su alimentación puede variar según la especie, pero entre sus favoritos se encuentran medusas, cangrejos y camarones.'},
            {'pregunta':'¿Cuáles son las especies de tortuga más comunes en la bahía de Mazatlán?', 'respuesta':'Tortuga golfina (Lepidochelys olivacea), tortuga carey (Eretmochelys imbricata), tortuga verde (Chelonia mydas) y tortuga caguama (Caretta caretta).'}
        ],
        'especies': [
            {
                'nombre_comun':'Sargento, petaca benderita, pintaño amarillo',
                'nombre_display':'Sargento',
                'nombre_cientifico':'Abudefduf troschelii',
                'conservacion':'Preocupación menor',
                'route':'sargento',
                'eng_route':'pacific-sergeant-major',
                'datos': [
                    'Los machos de este pez tienen jerarquías muy marcadas entre distintos tipos de machos.',
                    'Sus huevos parecen parches morados en las rocas.',
                    'Se han observado a juveniles de este pez limpiando a otros peces como el salmonete rayado, pajaritos y agujones.',
                ],
                'generalidades': [
                    'Este pez tiene escamas grandes y ásperas en el cuerpo y en la mayor parte de la cabeza. Es de color verde blanquecino o plateado pálido, con 5 barras negras en el lateral y espalda color amarillo brillante.',
                    'El adulto habita en arrecifes rocosos y de coral, y nada en grandes grupos en aguas cálidas.',
                    'Come plancton, invertebrados bentónicos y algas en el arrecife.',
                ],
            },
            {
                'nombre_comun':'Tortuga golfina',
                'nombre_cientifico':'Lepidochelys olivacea',
                'conservacion':'Vulnerable',
                'route':'golfina',
                'eng_route':'pacific-ridley',
                'datos': [
                    'Además  de la anidación en solitario, esta tortuga tiene un comportamiento de anidación en masa llamada arribazón. Miles de tortugas salen a anidar juntas.',
                    'Es la tortuga marina más abundante en el planeta.',
                    'Hace años su pesca y consumo era legal pero ahora está protegida.',
                ],
                'generalidades': [
                    'Cabeza de forma triangular, su caparazón es color oliva, circular y plano. Cuerpo color verde oliva a gris oscuro.',
                    'Se alimenta de peces, mejillones y crustáceos, especialmente camarones.',
                    'Puede alcanzar los 50kg de peso.',
                ],
            },
            {
                'nombre_comun':'Cochinito, cirujano aleta amarilla, barbero o navajón',
                'nombre_display':'Cochinito',
                'nombre_cientifico':'Prionurus punctatus',
                'conservacion':'Preocupación menor',
                'route':'cochinito',
                'eng_route':'yellowtail-surgeonfish',
                'datos': [
                    'Es vegetariano. Come algas según la temporada. Al ser hervíboro, su intestino es más largo que el de los peces carnívoros y su estómago es muscular, como una molleja.',
                    'Es diplomático: usa su columna vertebral pedúncula para abofetear a otros peces con el fin de resolver problemas territoriales.',
                ],
                'generalidades': [
                    'Este pez ovalado y comprimido tiene los ojos en posición muy arriba de la cabeza. Es color gris con pequeñas manchas negras pequeñas y una cola de color amarillo brillante.',
                    'Se puede encontrar en arrecifes poco profundos, en escuelas (grupos).',
                    'Se alimenta de algas en zonas rocosas.',
                ],
            },
        ],
    },
    {
        /* Bahía de las rayas */
        'id':'bahia-rayas',
        'nombre':'BAHÍA/DE LAS RAYAS',
        'habitat':'costa',
        'eng_carpet':'coast',
        'eng_link':'ray-bay',
        'description':'Vive momentos inolvidables en nuestra bahía de rayas, donde podrás interactuar y aprender de estos singulares y maravillosos peces.',
        'preguntas': [
            {'pregunta':'¿Qué son las rayas?', 'respuesta':'Son peces de esqueleto cartilaginoso, parientes cercanos de los tiburones. A diferencia de ellos, el cuerpo de las rayas es aplanado y sus branquias se ubican en la parte inferior del cuerpo.'},
            {'pregunta':'¿Dónde habitan las rayas?', 'respuesta':'En las zonas cercanas a la costa y en el mar abierto, se distribuyen a distintas profundidades y comúnmente se encuentran asociadas al fondo marino.'},
            {'pregunta':'¿De qué se alimentan las rayas?', 'respuesta':'Entre sus alimentos preferidos se encuentran camarones, almejas, poliquetos (gusanos), peces, caracoles y pulpos.'}
        ],
        'especies': [
            {
                'nombre_comun':'Raya mariposa, raya mariposa californiana',
                'nombre_display':'Raya mariposa',
                'nombre_cientifico':'Gymnura marmorata',
                'conservacion':'Casi amenazada',
                'route':'raya-mariposa',
                'eng_route':'butterfly-ray',
                'datos': [
                    'Cuenta con un pequeño aparato de veneno para defenderse.',
                    'La hembra guarda los huevos en su cuerpo, los embriones se alimentan de yema y luego se nutren de su madre.',
                    'La hembra es dos veces más grandes que el macho.',
                ],
                'generalidades': [
                    'Raya de disco más ancho que largo, es de color café con manchas pálidas y negruzcas. Cola delgada y extremadamente corta. Hocico chato, boca arqueada, ojos y espiráculo en el dorso, superficie dorsal lisa.',
                    'Vive en bahías, canales y estuarios arenosos o lodosos.',
                    'Come crustáceos y peces pequeños.',
                ],
            },
            {
                'nombre_comun':'Raya látigo largo',
                'nombre_display':'Raya/látigo largo',
                'nombre_cientifico':'Hypanus longus',
                'conservacion':'Vulnerable',
                'route':'raya-latigo',
                'eng_route':'longtail-stingray',
                'datos': [
                    'Pasan la mayor parte del tiempo en aguas profundas pero las hembras, que son más grandes que los machos, migran a zonas poco profundas en primavera para dar a luz.',
                ],
                'generalidades': [
                    'Llega a medir poco más de un metro. Su disco tiene forma de cometa, hocico largo con punta, ojos grandes, dorso gris, café rojizo o gris olivo, vientre blanco.',
                    'Este pez con esqueleto de cartílago se alimenta de moluscos, cangrejos y peces.',
                ],
            },
            {
                'nombre_comun':'Guitarra punteada',
                'nombre_cientifico':'Pseudobatos glaucostigma',
                'conservacion':'Vulnerable',
                'route':'punteada',
                'eng_route':'speckled-guitarfish',
                'datos': [
                    'Tiene pequeñas espinas en su dorso pero es suave al tacto.',
                    'Es la única especie de guitarra con manchas color gris pizarra en la cabeza y la parte superior del cuerpo, así que es fácil reconocerla.',
                ],
                'generalidades': [
                    'Cuerpo parecido al de tiburón y con gran cabeza triangular. Son de color café con manchas de color gris pizarra.',
                    'Nadan cerca del fondo y forman grandes grupos en aguas costeras sobre zonas arenosas y lodosas.',
                    'Los adultos se alimentan de langostinos y cangrejos, los juveniles de crustáceos más pequeños llamados anfípodos.',
                ],
            },
            {
                'nombre_comun':'Raya redonda',
                'nombre_cientifico':'Urobatis halleri',
                'conservacion':'Preocupación menor',
                'route':'raya-redonda',
                'eng_route':'round-stingray',
                'datos': [
                    'Las hembras emiten un campo eléctrico que los machos pueden percibir.',
                    'El apareamiento dura unos 5 minutos.',
                    'Suelen dar a luz a 6 crías vivas y los juveniles  permanecen cerca de la orilla donde hay menos depredadores.',
                ],
                'generalidades': [
                    'Raya de disco redondo, ligeramente más ancho que largo, piel lisa de color café claro o canela con manchas densas café oscuro.',
                    'Vive en fondos arenosos y lodosos de playas y pantanos.',
                    'Se alimenta de invertebrados del suelo marino y peces pequeños.',
                ],
            },
            {
                'nombre_comun':'Chucho dorado, gavilán dorado, raya tecolote',
                'nombre_display':'Chucho dorado',
                'nombre_cientifico':'Rhinoptera steindachneri',
                'conservacion':'Casi amenazado',
                'route':'chucho-dorado',
                'eng_route':'golden-cownose-ray',
                'datos': [
                    'A menudo forma escuelas, a veces se asocia con otra raya llamada gavilán pintado.',
                    'En las hembras sólo el ovario y útero izquierdo son funcionales, de hecho tienen pocas crías, sólo un embrión por hembra al año.',
                ],
                'generalidades': [
                    'Tiene forma de diamante con la cabeza saliendo distintivamente del disco; cola muy delgada, piel lisa. Es de color café amarillento pálido, vientre blanco y cola negra.',
                    'Habita sobre fondos blandos, cerca de arrecifes rocosos o de coral y lagunas costeras. Ocasionalmente cerca de la superficie y puede saltar fuera del agua.',
                    'Se alimenta de crustáceos del fondo y moluscos.',
                ],
            },
            {
                'nombre_comun':'Raya moteada',
                'nombre_cientifico':'Urotrygon chilensis',
                'conservacion':'Casi amenazada',
                'route':'raya-moteada',
                'eng_route':'blotched-tingray',
                'datos': [
                    'Es una especie ovovivípara, es decir, gesta a sus crías en huevos que mantiene en su interior hasta que nacen.'
                ],
                'generalidades': [
                    'Su cuerpo es ovalado, con grandes ojos y una cola delgada sobre la cual presenta una espina. Se distribuye del Golfo de California a Perú y Chile, entre 1 y 60m de profundidad. Su alimento principal son pequeños crustáceos, moluscos, gusanos y peces pequeños.'
                ],
            },
        ]
    },
    {
        /* Rompiente */
        'id':'rompiente',
        'nombre':'ROMPIENTE',
        'habitat':'costa',
        'eng_carpet':'coast',
        'eng_link':'surf-zone',
        'description':'Siente el poder del mar y déjate impresionar por la imponente fuerza del agua. ¡La vida también florece bajo el oleaje!',
        'preguntas': [
            {'pregunta':'¿Qué es la zona de rompiente?', 'respuesta':'Es la franja de la zona costera en que la energía de las olas da forma a la tierra.'},
            {'pregunta':'¿Qué animales viven en la zona de rompiente?', 'respuesta':'En esta zona puedes encontrar una gran variedad de fauna marina, como caracoles, almejas, balanos, percebes, quitones, erizos de mar y estrellas de mar.'},
            {'pregunta':'¿Cuál es la importancia ecológica de la zona de rompiente?', 'respuesta':'Es hogar de diversas especies marinas y un área de refugio para las larvas de algunos peces y crustáceos.'}
        ],
        'especies': [
            {
                'nombre_comun':'Cucaracha de mar, lengua de perro',
                'nombre_display':'Cucaracha de mar',
                'nombre_cientifico':'Chitón articulatus',
                'conservacion':'Datos deficientes',
                'route':'cucaracha-mar',
                'eng_route':'sea-cockroach',
                'datos': [
                    'Es un basibionte, lo que significa que otros seres vivos viven sobre él.',
                    'Es el alimento favorito del caracol Plicopúrpura pansa (especie protegida), pero también se ofrece como platillo en algunos restaurantes.',
                ],
                'generalidades': [
                    'Forma oval con una armadura articulada formada por placas duras que protegen al organismo. Debajo tiene un pie muscular y valvas que le ayudan a fijarse mejor a las rocas en donde vive. Son de color verde olivo a café.',
                    'Se les encuentra frecuentemente en zonas de rompiente de olas.',
                    'Se alimenta de restos y desperdicios orgánicos, aunque por mucho tiempo se pensó que eran herbívoros.',
                ],
            },
            {
                'nombre_comun':'Damisela cabezona',
                'nombre_cientifico':'Microspanthodon bairdii',
                'conservacion':'Preocupación menor',
                'route':'damisela-cabezona',
                'eng_route':'bumphead-damselfish',
                'datos': [
                    'Depositan sus huevos en grietas entre las rocas en zonas de fuertes corrientes, donde los machos los protegen y airean.',
                ],
                'generalidades': [
                    'El adulto tiene una joroba, puede ser de color gris, café oscuro a cenizo, a veces con cabeza blanca o amarillenta. Los juveniles son de color azul brillante por arriba y anaranjado por debajo.',
                    'Habita en arrecifes rocosos costeros de las olas y las corrientes.',
                    'Se alimentan principalmente de microalgas bentónicas, crustáceos y gusanos.',
                ],
            },
            {
                'nombre_comun':'Damisela gigante',
                'nombre_cientifico':'Microspathodon dorsalis',
                'conservacion':'Preocupación menor',
                'route':'damisela-gigante',
                'eng_route':'giant-damselfish',
                'datos': [
                    'Defiende sus territorios de alimentación y reproducción ahuyentando a peces y buzos que se acercan mucho.',
                    'Sus huevos se adhieren al sustrato en donde los machos los protegen y airean hasta que nacen los pequeños.',
                ],
                'generalidades': [
                    'Cuerpo grueso y aplastado de color gris azulado oscuro, o casi negro. Llega a medir hasta 31 cm.',
                    'Se alimentan de algas, gusanos marinos, crustáceos y parásitos.',
                ],
            },
            {
                'nombre_comun':'Mejillón californiano',
                'nombre_cientifico':'Mytilus californianus',
                'conservacion':'No evaluado',
                'route':'mejillon-californiano',
                'eng_route':'california-mussel',
                'datos': [
                    'Las camas de mejillones atrapan agua, lodo y materia que proporciona alimento y refugio para una increíble variedad de animales y plantas.',
                    'Los mejillones se pueden volver peligrosos para consumo humano cuando hay florecimientos algales como las mareas rojas. Son filtradores y se alimentan de los dinoflagelados que forman estas mareas y que pueden producir toxinas.',
                ],
                'generalidades': [
                    'La concha es de color negro azulado, a menudo con válvulas blancas erosionadas y más oscuras en los márgenes. El extremo de atrás de la concha es puntiagudo.',
                    'Vive en rocas expuestas al oleaje y pilotes de muelles. Abundante en la zona intermareal.',
                    'Para alimentarse filtra agua y atrapa materia orgánica y plancton.',
                ],
            },
            {
                'nombre_comun':'Ostión de roca',
                'nombre_cientifico':'Striostrea prismatica',
                'conservacion':'No evaluado',
                'route':'ostion-roca',
                'eng_route':'rock-oyster',
                'datos': [
                    'Los ostiones son excelentes indicadores de la salud del medio ambiente marino, funcionan como bio-monitores porque filtran y acumulan contaminantes.',
                ],
                'generalidades': [
                    'Concha ovalada o alargada, pesada y gruesa. Por fuera es de color café-púrpura y por dentro, blanco iridiscente con parches de color café.',
                    'Vive en la zona rocosa donde sube y baja la marea.',
                    'Se alimenta de fitoplancton y materia orgánica.',
                ],
            },
            {
                'nombre_comun':'Erizo de mar blanco o café',
                'nombre_display':'Erizo/de mar blanco',
                'nombre_cientifico':'Tripneustes depressus',
                'conservacion':'No evaluado',
                'route':'erizo',
                'eng_route':'white-sea-urchin',
                'datos': [
                    'Se ha probado que el líquido celómico de este erizo de mar tiene actividad antiviral contra la rabia y el herpesvirus porcino.',
                    'Crece muy rápido comparado con la mayoría de los erizos.',
                    'Tienen células fotorreceptoras y se ha sugerido que todo el cuerpo del erizo funciona como un gran ojo compuesto.',
                ],
                'generalidades': [
                    'Cuerpo globoso comprimido con una base café-rojiza y espinas con una base del mismo color y blanco – amarillento del medio hacia la punta.',
                    'Habita en fondos rocosos cubiertos por algas, pastos marinos, planicies de arrecifes de coral.',
                    'Más común en aguas poco profundas. Se alimenta de algas y algunos invertebrados.',
                ],
            },
        ]
    },

    /* TIERRA */
    {
        /* TERRARIO */
        'id':'terrario',
        'nombre':'TERRARIO',
        'habitat':'tierra',
        'eng_carpet':'land',
        'eng_link':'terrarium',
        'description':'Descubre fascinantes seres de escamas y pieles resbalosas. Conoce a los eslabones clave para que la vida saliera del agua y se adaptara a la tierra. Estamos seguros de que te encantará.',
        'preguntas': [
            {'pregunta':'¿Qué es la herpetofauna?', 'respuesta':'Es el conjunto de animales anfibios y reptiles conocidos por no contar con pelaje y ser de “sangre fría”, es decir, que no regulan su temperatura (como nosotros) y dependen del ambiente para mantener su temperatura corporal ideal. México es el segundo lugar mundial en riqueza de herpetofauna.'},
            {'pregunta':'¿Qué son los anfibios?', 'respuesta':'Son animales de piel lisa, sin escamas, plumas o pelo. Viven cerca del agua, ya que sus huevos dependen de la humedad para desarrollarse. A este grupo pertenecen las ranas, sapos, salamandras y axolotes.'},
            {'pregunta':' ¿Qué son los reptiles?', 'respuesta':'Se trata de animales conocidos por su piel con escamas y cuerpos que les permiten moverse en ambientes secos y extremos. Fueron los primeros en ser independientes del agua para su reproducción, ya que sus huevos tienen un cascaron duro que los protege de la pérdida de agua. Animales como serpientes, tortugas, lagartijas e iguanas forman este grupo.'},
        ],
        'especies': [
            {
                'nombre_comun':'Sapito de Mazatlán o sapo sinaloense',
                'nombre_display':'Sapito de Mazatlán',
                'nombre_cientifico':'Incilius mazatlenensis',
                'conservacion':'Preocupación menor',
                'route':'sapito-mazatlan',
                'eng_route':'sinaloa-toad',
                'datos': [
                    'Es una especie que solo se encuentra en México.',
                    'Se descubrió en Mazatlán, Sinaloa, de ahí su nombre.',
                ],
                'generalidades': [
                    'Sapo robusto y de piel rasposa. Llega a medir casi 9 cm.',
                    'Vive en la selva baja caducifolia, bosques tropicales y secos, así como en pinares bajos.',
                    'Se alimenta de escarabajos, hormigas y otros insectos.',
                ],
            },
            {
                'nombre_comun':'Boa común, ilama, mazacoatl, limacoa',
                'nombre_display':'Boa común',
                'nombre_cientifico':'Boa constrictor imperator',
                'conservacion':'Preocupación menor',
                'route':'boa-comun',
                'eng_route':'common-boa',
                'datos': [
                    'Llega a vivir hasta 25 o 30 años.',
                    'No es venenosa.',
                    'Como su nombre lo indica: constrictor, aprieta a sus presas con el cuerpo para atraparlas, asfixiarlas y luego comerlas, pero no puede comer presas demasiado grandes. Al parecer algunas boas han muerto por intentar comer tlacuaches.',
                ],
                'generalidades': [
                    'Mide de 2 a 3 metros. Cuerpo gris, con marcas dorsales. Cabeza con tres franjas oscuras.',
                    'Es más activa en la tarde y noche. Es solitaria.',
                ],
            },
            {
                'nombre_comun':'Escorpión mexicano, lagarto enchaquirado.',
                'nombre_display':'Escorpión mexicano',
                'nombre_cientifico':'Heloderma horridum',
                'conservacion':'Preocupación menor',
                'route':'escorpion-mexicano',
                'eng_route':'mexican-beaded',
                'datos': [
                    'Es una de las dos lagartijas venenosas que existen en el mundo.',
                    'Utiliza su veneno como defensa, pero no lo inyecta sino que escurre por sus dientes acanalados desde unas glándulas.',
                    'Es de gran importancia médica pues las toxinas de su veneno tienen usos potenciales en medicina.',
                ],
                'generalidades': [
                    'Saurio de unos 40 centímetros, de cabeza y cuerpo grande, cola corta.',
                    'Habita en la costa del Pacífico mexicano. Su mayor actividad es durante la madrugada o al anochecer. Son lentos y tranquilos.',
                    'Se alimenta de huevos y presas pequeñas. Cada vez son más escasos.',
                ],
            },
        ]
    },
    {
        /* AVIARIO */
        'id':'aviario',
        'nombre':'AVIARIO',
        'habitat':'tierra',
        'description':'Entre jardines y una grandiosa vista del acuario, conoce los colores y cantos de bellas aves.',
        'preguntas': [
            {'pregunta':'¿Cuántas especies de aves hay en México?', 'respuesta':'México es un país con gran variedad de aves, ocupa el 1er lugar en Norteamérica con mil 107 especies y el doceavo a nivel mundial. Dentro del gran acuario podrás conocer algunas de ellas.'},
            {'pregunta':'¿Por qué migran las aves?', 'respuesta':'Algunas aves viajan en búsqueda de ambientes más favorables para su reproducción y alimentación, México es uno de los lugares preferidos de muchas de ellas.'},
            {'pregunta':'¿Por qué cantan las aves?', 'respuesta':'Usan su canto para comunicarse entre ellas y cada especie tiene un sonido que las distingue. En tu visita podrás escucharlas.'},
        ],
        'especies': [
            {
                'nombre_comun':'Capiro',
                'nombre_cientifico':'Albizia sinaloensis',
                'conservacion':'Pendiente',
                'route':'capiro',
                'datos': [
                    'Pendiente',
                ],
                'generalidades': [
                    'Pendiente',
                ],
            },
        ]
     },
    {
        /* JARDINES */
        'id':'jardines',
        'nombre':'JARDINES',
        'habitat':'tierra',
        'eng_carpet':'land',
        'eng_link':'gardens',
        'description':'Asómbrate y conoce la variedad de plantas que forman parte del acuario; seres vivos que nos proveen de oxígeno, refrescan y alegran te acompañarán en tu recorrido.',
        'preguntas': [
            {'pregunta':'¿Qué es la vegetación?', 'respuesta':'Es el nombre que se le da a las comunidades de plantas que conviven y se desarrollan en un lugar o región.'},
            {'pregunta':'¿Qué importancia tienen las plantas?', 'respuesta':'Nos permiten tener un ambiente agradable, ya que con apoyo de sus hojas, ramas y tallos, guardan humedad y regulan la temperatura. Además, capturan el dióxido de carbono y producen oxígeno.'},
            {'pregunta':'¿Qué es una especie endémica?', 'respuesta':'Son aquellas especies (incluyendo a las plantas) que viven en un área limitada. Dentro de los jardines podrás encontrar plantas que solo se encuentran en esta región.'},
        ],
        'especies': [
            {
                'nombre_comun':'Capiro',
                'nombre_cientifico':'Albizia sinaloensis',
                'conservacion':'No evaluado',
                'route':'capiro',
                'eng_route':'alamos-tree',
                'datos': [
                    'Es un árbol perennifolio, esto quiere decir que conserva su follaje durante todo el año.',
                ],
                'generalidades': [
                    'Este árbol puede crecer hasta 30m de altura. Sus hojas se distribuyen de forma alterna, su corteza presenta una coloración amarillenta y suele tener muchas flores extendidas. En México se encuentra en los estados de Sinaloa y Sonora.',
                ],
            },
            {
                'nombre_comun':'Venadillo',
                'nombre_cientifico':'Swietenia humilis',
                'conservacion':'En peligro',
                'route':'venadillo',
                'eng_route':'pacific-coast',
                'datos': [
                    'Es apreciado por su madera que se utiliza para la elaboración de muebles finos, instrumentos musicales y decoración de interiores.',
                ],
                'generalidades': [
                    'Puede medir hasta 25m de altura y hasta un metro de diámetro. Comienza a florecer cuando tiene entre 15 - 25 años, entre los meses de marzo y abril.',
                ],
            },
            {
                'nombre_comun':'Huanacaxtle',
                'nombre_cientifico':'Enterolobium cyclocarpum',
                'conservacion':'Preocupación menor',
                'route':'huanacaxtle',
                'eng_route':'guanacaste',
                'datos': [
                    'Es de importancia comercial por su madera en la ebanistería y para uso artesanal. Sus frutos son usados como alimento para el ganado y son también de uso medicinal tradicional.',
                ],
                'generalidades': [
                    'Es un árbol grande y llamativo que puede medir hasta 45m de altura. Esta especie es nativa de México, Centroamérica y Norte de Sudamérica. Sus frutos surgen entre los meses de mayo y julio.',
                ],
            },
            {
                'nombre_comun':'Amapa',
                'nombre_cientifico':'Tabebuia rosea',
                'conservacion':'Preocupación menor',
                'route':'amapa',
                'eng_route':'rosy-trumpet',
                'datos': [
                    'Sus hojas y corteza son utilizadas para tratar la fiebre, tifoidea y parásitos. Sus bellas flores tienen forma de trompeta y atraen a diversos polinizadores.',
                ],
                'generalidades': [
                    'Es un árbol caducifolio, es decir, pierde sus hojas en temporada de secas. Suele medir entre  15 - 25m y hasta 30m. Florece de febrero a junio y da frutos entre los meses de marzo y junio.',
                ],
            },
            {
                'nombre_comun':'Tabachín',
                'nombre_cientifico':'Delonix regia',
                'conservacion':'Preocupación menor',
                'route':'tabachin',
                'eng_route':'royal-poinciana',
                'datos': [
                    'Sus hojas y flores son empleadas en la medicina tradicional de algunas etnias. Se le considera un árbol de crecimiento rapido ya que crece de 1 a 2 metros por año.',
                ],
                'generalidades': [
                    'Se distribuye en ambientes de clima cálido con humedad, llegan a medir de 8 a 12 metros de altura y presentan unas llamativas flores en colores rojo, naranja y amarillo.',
                ],
            },
            {
                'nombre_comun':'Guamúchil',
                'nombre_cientifico':'Pithecellobium dulce',
                'conservacion':'Preocupación menor',
                'route':'guamuchil',
                'eng_route':'manila-tamarind',
                'datos': [
                    'Produce un fruto comestible en una vaina que tiende a enrollarse en forma de espiral. Tambien ha sido utilizado con propósitos de reforestación y la producción de leña.',
                ],
                'generalidades': [
                    ' Nativo de México, Centroamérica y Sudamérica. Son de crecimiento rápido y llegan a alcanzar los 25 metros de altura.',
                ],
            },
            {
                'nombre_comun':'Guayacán',
                'nombre_cientifico':'Tabebuia guayacan',
                'conservacion':'Preocupación menor',
                'route':'guayacan',
                'eng_route':'guayacan',
                'datos': [
                    'Es apreciado en algunas etnias por su uso como planta medicinal.',
                ],
                'generalidades': [
                    'Es un árbol nativo del norte de méxico de ambientes cálidos; presenta flores color amarillo y puede llegar a crecer hasta los 15 metros de altura.',
                ],
            },
            /*
            {
                'nombre_comun':'Palma de chicle',
                'nombre_cientifico':'Dioon spinulosum',
                'conservacion':'En peligro',
                'route':'palma-chicle',
                'datos': [
                    'Su fruto es el más grande de las cícadas y uno de los más grande del grupo de las gimnospermas, llegando a pesar hasta 25 kg. Sus hojas se utilizan en ceremonias.',
                ],
                'generalidades': [
                    'De tronco robusto, llegan a crecer hasta 15metros de altura y agrupa sus hojas en forma de corona. Su distribución comprende desde el centro de México hasta Honduras.',
                ],
            },
            */
            {
                'nombre_comun':'Apompo, Guayana, castaño silvestre, money tree',
                'nombre_display':'Apompo',
                'nombre_cientifico':'Pachira aquatica',
                'conservacion':'Preocupación menor',
                'route':'apompo',
                'eng_route':'malabar-chestnut',
                'datos': [
                    'Sus hojas, flores y nueces son todos comestibles.',
                    'Sus flores están entre las flores de árboles más grandes del mundo.',
                ],
                'generalidades': [
                    'Árbol de zona tropical, nativo de América Central y sur. Puede llegar a medir hasta 12m.',
                    'Sus flores se abren como cáscaras de plátano, tienen pétalos largos y estrechos de color amarillo verdoso o crema. Produce nueces que crecen en vainas verdes.',
                ],
            },
        ]
     },

    /* MAR */
    { 
        'id':'punto-pulmo',
        'nombre':'PUNTO PULMO',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'punto-pulmo',
        'description':'Una ventana al fascinante mundo del coral vivo.',
        'preguntas': [
            {'pregunta': '¿Qué es un coral?', 'respuesta': 'Son animales marinos que viven en estrecha relación con algas microscópicas, las cuales se introducen en el coral y les proveen alimento.'},
            {'pregunta': '¿Cuántas especies de corales formadores de arrecifes existen en el Mar de Cortés?', 'respuesta': 'Existen 14 especies de corales formadores de arrecifes (hermatípicos) y 11 de ellas se encuentran en el Parque Nacional Cabo Pulmo.'},
            {'pregunta': '¿En qué profundidades podemos encontrar a los corales formando arrecifes arrecifes?', 'respuesta': 'Es común encontrar arrecifes entre los 0 a 12 m, debido a que conforme aumenta la profundidad hay menor luz, la cual es una fuente de energía para el crecimiento de los corales.'},
        ],
        'especies': [
            {
                'nombre_comun':'Cirujano reo',
                'nombre_cientifico':'Acanthurus triostegus',
                'conservacion':'Preocupación menor',
                'route':'cirujano-reo',
                'eng_route':'convict-surgeonfish',
                'datos': [
                    'Pueden detectar vibraciones y poseen un fuerte sentido del olfato y el sonido.',
                    'Pueden fingir la muerte exhibiendo inmovilidad tónica para evitar la depredación.',
                    'En sus intestinos viven unas de las bacterias más grande del mundo y se cree que éstas le ayudan a digerir los alimentos.',
                ],
                'generalidades': [
                    'Tiene forma de óvalo alargado, cabeza con perfil empinado, ojos muy arriba de la cabeza. Es de color blancuzco a gris verdoso claro con 6 barras negras angostas en cabeza y cuerpo.',
                    'De adultos habitan en arrecifes de laguna y mar con sustrato duro; mientras que los jóvenes son  abundantes en pozas de marea.',
                    'Se alimentan de microalgas.',
                ],
            },
            {
                'nombre_comun':'Jaqueta de Cortés, Castañeta azulada',
                'nombre_display':'Castañeta azulada',
                'nombre_cientifico':'Stegastes rectifraenum',
                'conservacion':'Preocupación menor',
                'route':'castaneta-azulada',
                'eng_route':'cortez-damselfish',
                'datos': [
                    'Al comer algas en su territorio de manera constante, ayuda a regular su crecimiento y a mejorar la productividad del arrecife.',
                    'Es un pez endémico y uno de los más abundantes a lo largo de la costa del Pacífico mexicano.',
                ],
                'generalidades': [
                    'Pez de cuerpo comprimido ovalado. Los adultos son de color café, oscuro el cuerpo y cabeza pálida. Los juveniles son de color azul brillante y un par de rayas azul neón en la parte superior de la cabeza. Tienen una mancha negra en la aleta dorsal.',
                    'Habita arrecifes rocosos poco profundos de la zona de oleaje.',
                    'Son omnívoros, comen de todo un poco.',
                ],
            },
            {
                'nombre_comun':'Arcoiris Cortés',
                'nombre_cientifico':'Thalassoma lucasanum',
                'conservacion':'Preocupación menor',
                'route':'arcoiris-cortes',
                'eng_route':'cortez-rainbow-wrasse',
                'datos': [
                    'Prácticamente desaparecen cuando la temperatura del agua baja de los 27°C.',
                    'Tienen un movimiento particular de arriba a abajo cuando usan sus aletas pectorales. ¡Ven a verlos!',
                    'Hay dos tipos de machos y dos sistemas de reproducción, y a veces las  hembras pueden transformarse en machos.',
                ],
                'generalidades': [
                    'Cuerpo delgado y alargado. Las hembras y los machos  tienen un cuerpo rayado con una franja amarilla y otra azul verdoso, una línea amarilla, otra roja y vientre blanco.',
                    'Otro tipo de machos son de cabeza color azul oscuro, una barra amarilla ancha detrás de su cabeza, cola azulada, cuerpo púrpura y aletas pectorales amarillas.',
                    'Vive en agregaciones en y alrededor de arrecifes rocosos y de coral, en pozas intermareales.',
                    'Come plancton y pequeños invertebrados.',
                ],
            },
            /*
            {
                'nombre_comun':'Gorgonias marinas',
                'nombre_cientifico':'Eugorgia sp.',
                'conservacion':'No evaluado',
                'route':'gorgonias-marinas',
                'datos': [
                    'Pertenecen a una familia de corales conocidos como "Abanicos de mar".',
                ],
                'generalidades': [
                    'Es un género de corales que se distribuye en el Océano Pacífico Oriental. Su cuerpo cuenta con',
                ],
            },
            */
            {
                'nombre_comun':'Coral copa naranja ',
                'nombre_cientifico':'Tubastrea occinea',
                'conservacion':'No evaluado',
                'route':'coral',
                'eng_route':'orange-cup-coral',
                'datos': [
                    'Esta especie puede vivir sobre materiales artificiales como el granito, cemento y el acero.',
                    'Es una especie invasora, se extendido por el Atlántico occidental durante 60 años.',
                    'Pueden crecer en completa oscuridad siempre que puedan capturar suficiente comida.',
                ],
                'generalidades': [
                    'Las colonias son de color naranja o rojo, amarillo brillante, rojo rosado a rosa. Sus pólipos suelen extenderse por la noche.',
                    'Estos corales no forman arrecifes pero pueden vivir cerca de ellos, en rocas y hasta en aguas frías.',
                ],
            },
            {
                'nombre_comun':'Pavona gigantea',
                'nombre_cientifico':'Pavona gigantea',
                'conservacion':'Preocupación menor',
                'route':'pavona',
                'eng_route':'pavona-gigantea',
                'datos': [
                    'Sus colonias son masivas y son importantes formadoras de arrecifes.',
                    'Es uno de los corales más comunes de la costa del Pacífico mexicano.',
                ],
                'generalidades': [
                    'Es un coral grande, grueso, incrustante, de color verde, gris y marrón. Superficie superior casi plana o diversamente ondulada y desigual, cubierta de celdas grandes, distantes y estrelladas.',
                    'Habita en fondos rocosos de aguas tropicales poco profundas.',
                    'Recibe alimento de unas algas con las que se asocia y también captura algo de plancton.',
                ],
            },
        ]
    },
    { 
        'id':'ciudad-coral',
        'nombre':'CIUDAD CORAL',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'coral-city',
        'description':'Los arrecifes son las metrópolis del océano, ¡déjate atrapar por los colores, vida y luces de la ciudad coral!',
        'preguntas': [
            {'pregunta': '¿Qué es un coral?', 'respuesta': 'Son animales marinos que viven en estrecha interacción con algas microscópicas, las cuales se introducen en el coral y a través de la fotosíntesis les proveen de energía.'},
            {'pregunta': '¿Cómo se forman los arrecifes?', 'respuesta': 'Los pequeños corales individuales (pólipos) forman colonias que se protegen con un esqueleto de carbonato de calcio. Estas estructuras en conjunto constituyen a los arrecifes.'},
            {'pregunta': '¿Qué importancia tienen los arrecifes de coral?', 'respuesta': 'Son ecosistemas altamente diversos, es decir, albergan una gran variedad de vida marina. Sirven como área de refugio y alimentación de distintas especies.'},
        ],
        'especies': [
            /*
            {
                'nombre_comun':'Botete de aletas punteadas',
                'nombre_display':'Botete/de aletas punteadas',
                'nombre_cientifico':'Arothron meleagris',
                'conservacion':'Preocupación menor',
                'route':'botete-aletas',
                'datos': [
                    'Se inflan como mecanismo de defensa. Al igual que muchos botetes/globos, presentan una neurotoxina en su piel, vísceras y gónadas. Su distribución dentro del Pacífico Oriental comprende desde las costas de Guaymas Sonora hasta el Ecuador.',
                ],
                'generalidades': [
                    'Pez de cuerpo redondeado, de color negro uniforme cubierto con numerosas manchas blancas pequeñas. Vive en áreas de arrecifes rocosos y de coral. Se alimenta de coral, pequeños animales marinos, esponjas, algas y erizos de mar.',
                ],
            },
            
            {
                'nombre_comun':'Cirujano aleta amarilla',
                'nombre_display':'Cirujano/aleta amarilla',
                'nombre_cientifico':'Acanthurus xanthopterus',
                'conservacion':'Preocupación menor',
                'route':'cirujano-gris',
                'datos': [
                    'Es venenoso y es el más grande de los Peces Cirujano.',
                    'En aguas mexicanas tienen una distribución limitada, solo se encuentra en el extremo sur del Mar de Cortés entre La Paz y Cabo San Lucas, y a lo largo de la costa del continente desde Acapulco hasta Guatemala.',
                ],
                'generalidades': [
                    'Pez de color gris violáceo, aletas parcialmente amarillas.',
                    'Vive en varios hábitats de arrecifes, laderas de arena y lagunas. Los juveniles prefieren aguas costeras poco profundas, protegidas y turbias, mientras que los adultos prefieren áreas más profundas de bahías y lagunas.',
                    'Se alimentan durante el día de diatomeas, detritos de arena, algas filamentosas, hidroides y trozos de pescado.'
                ],
            },
            */
            {
                'nombre_comun':'Cirujano cola amarilla',
                'nombre_display':'Cirujano/cola amarilla',
                'nombre_cientifico':'Prionurus laticlavius',
                'conservacion':'Preocupación menor',
                'route':'cirujano',
                'eng_route':'razor-surgeonfish',
                'datos': [
                    'Generalmente se mueven en cardúmenes y pueden sumergirse hasta los 30m de profundidad algo no muy común en peces como ellos. Se distribuyen del Golfo de California a El Salvador, incluyendo las Islas Revillagigedo.',
                ],
                'generalidades': [
                    'De cuerpo ovalado y comprimido color gris, con pequeñas manchas negras y presenta dos barras oscuras en la cabeza, su cola es color amarillo y cuenta con una boca pequeña que se proyecta. Vive en fondos del área costera, asociado a arrecifes.',
                    'Se alimenta de microalgas y macroalgas bentónicas, así como hierbas marinas.',
                ],
            },
            {
                'nombre_comun':'Mero pintado o cabrilla piedrera',
                'nombre_display':'Mero pintado',
                'nombre_cientifico':'Epinephelus labriformis',
                'conservacion':'Preocupación menor',
                'route':'mero',
                'eng_route':'starry-grouper',
                'datos': [
                    'Es un pez hermafrodita protógino, es decir, funciona primero como hembra y luego de transforma en macho.',
                ],
                'generalidades': [
                    'Pez robusto de color café rojizo que está cubierto de manchas blancas de tamaños irregulares. Tienen una marca negra característica en la base de su aleta caudal que baja hacia los costados.',
                    'Habita en áreas rocosas de aguas costeras.',
                    'Durante el día se alimenta de peces pequeños y de crustáceos durante la noche.',
                ],
            },
            {
                'nombre_comun':'Ángel de Clarión',
                'nombre_cientifico':'Holacanthus clarionensis ',
                'conservacion':'Vulnerable',
                'route':'angel',
                'eng_route':'clarion-angelfish',
                'datos': [
                    'Es endémica de las islas Revillagigedo y Clipperton, a veces presente en el Golfo de California.',
                    'Es el principal pez limpiador de las mantarrayas (Manta birostris).',
                ],
                'generalidades': [
                    'Pez de cuerpo rectangular. Generalmente es de cuerpo color naranja parduzco, cabeza café oscuro, una banda anaranjada  brillante detrás de la cabeza y una aleta caudal naranja. Los juveniles son de color naranja parduzco con rayas azules estrechas en la cabeza y los lados.',
                    'Come esponjas, tunicados, hidrozoarios, crustáceos, moluscos, plancton y algas.',
                    'Vive en arrecifes rocosos, los juveniles son solitarios y territoriales, los adultos se mueven sobre el fondo en grupos de hasta 30 individuos.',
                ],
            },
            {
                'nombre_comun':'Ángel real o Ángel rey',
                'nombre_display':'Ángel real',
                'nombre_cientifico':'Holacanthus passer',
                'conservacion':'Preocupación menor',
                'route':'angel_real',
                'eng_route':'king-angelfish',
                'datos': [
                    'Las hembras son más territoriales y forman vínculos de pareja con los machos.',
                    'Se ha observado que los juveniles limpian a otros peces.',
                ],
                'generalidades': [
                    'Pez de forma rectangular. El adulto es de color gris azul oscuro con una estrecha banda blanca vertical, aletas color amarillo-naranja, y una mancha naranja  en la esquina de la boca. Los juveniles son multicolores de rayas verticales.',
                    'Se alimenta de esponjas, invertebrados, plancton y algas.',
                    'Vive en arrecifes rocosos y de coral.',
                ],
            },
            {
                'nombre_comun':'Ángel de Cortés',
                'nombre_cientifico':'Pomacanthus zonipectus',
                'conservacion':'Preocupación menor',
                'route':'angel-cortes',
                'eng_route':'angel-cortes',
                'datos': [
                    'Los adultos suelen desplazarse en parejas o en agregaciones, mientras que los juveniles son territoriales y se desplazan de manera individual.',
                    'Suelen relacionarse con una sola pareja.',
                ],
                'generalidades': [
                    'Cuerpo alto y comprimido, con un hocico corto; boca pequeña con dientes en forma de cerdas. Su color es grisáceo con bandas negras y amarillas, cola color amarillo pálido. Los juveniles se distinguen por contar con bandas azules y amarillas en su cuerpo.',
                    'Se alimenta de corales suaves, estrellas de mar, pepinos, anémonas y esponjas.',
                ],
            },
            {
                'nombre_comun':'Mariposa rayada o Ídolo moro',
                'nombre_display':'Mariposa rayada',
                'nombre_cientifico':'Zanclus cornutus',
                'conservacion':'Preocupación menor',
                'route':'mariposa-rayado',
                'eng_route':'mariposa-rayado',
                'datos': [
                    'Son peces activos que disfrutan pasar el día explorando su habitat. Por lo general son sociales con peces de otras especies.',
                ],
                'generalidades': [
                    'Tiene su cuerpo en forma de disco con una aleta dorsal alargada similar a un látigo. Su coloración es blanca con franjas de color negro, cuenta con 2 manchas color amarillo, una en la mitad trasera de cuerpo y otra cercana a su boca.',
                    'Vive asociado a los arrecifes de coral, donde se alimenta de algas, esponjas, krill o pequeños peces.',
                ],
            },
        ]
    },
    { 
        'id':'establo-marino',
        'nombre':'ESTABLO MARINO',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'sea-stable',
        'description':'La sutil cabalgata de estos magníficos peces te va a encantar. No pierdas la oportunidad de ver a los caballitos de mar en su establo marino.',
        'preguntas': [
            {'pregunta': '¿Qué es un caballito de mar?', 'respuesta': 'Los caballitos de mar son peces, los cuales reciben su nombre común debido a la forma de su cabeza.'},
            {'pregunta': '¿Cuál es el hábitat de los caballitos de mar?', 'respuesta': 'Viven en aguas cálidas, comunmente se asocian a arrecifes marinos y zonas de pastos marinos.'},
            {'pregunta': '¿Cuál es la especie de caballito de mar más grande?', 'respuesta': 'El caballito de mar del Pacífico (Hippocampus ingens) es una de las especies más grandes del mundo. Alcanza hasta 30 cm de largo.'},
        ],
        'especies': [
            {
                'nombre_comun':'Pez pipa chica o enana',
                'nombre_display':'Pez pipa chica',
                'nombre_cientifico':'Doryrhamphus excisus',
                'conservacion':'Preocupación menor',
                'route':'pipa_chica',
                'eng_route':'blue-striped-pipefish',
                'datos': [
                    'Utiliza su hocico tubular para ingerir su comida de manera similar al caballito de mar.',
                    'Es monógamo y el macho lleva los huevos en una bolsa de cría que se encuentra debajo de la cola.',
                ],
                'generalidades': [
                    'Hocico largo con cresta central espinosa. Es de color rojizo a café, con una banda azul en la parte superior del cuerpo y una negruzca a los lados del hocico.',
                    'Es poco común, se camufla bien; prefiere estar en grietas en rocas y corales y áreas debajo de repisas.',
                    'Se alimenta de pequeños crustáceos y plancton.',
                ],
            },
            {
                'nombre_comun':'Caballito de mar del Pacífico',
                'nombre_display':'Caballito de mar/del Pacífico',
                'nombre_cientifico':'Hippocampus ingens',
                'conservacion':'Vulnerable ',
                'route':'caballito',
                'eng_route':'pacific-seahorse',
                'datos': [
                    'Es una de las más grandes de las cerca de 50 especies de caballitos conocidas en el mundo.',
                    'No tienen dientes ni estómago y succiona su alimento, como si comiera con popote.',
                    'La hembra deposita los huevos en la bolsa de incubación del macho donde permanencen hasta nacer los pequeños caballitos.',
                ],
                'generalidades': [
                    'Pez de cuello curvo con la cabeza casi en ángulo recto respecto al cuerpo, el cual está cubierto de anillos óseos.',
                    'Hocico alargado, estructura en forma de corona en la cabeza. Es de color variable para camuflarse en su medio.',
                    'Es más activo en la noche y habita en aguas costeras.',
                    'Se alimenta de zooplancton y crustáceos.',
                ],
            },
        ]
     },
     { 
        'id':'espejos',
        'nombre':'ESPEJOS',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'lookdowns',
        'description':'Una gran columna de agua se eleva cerca del corazón del acuario. En ella, espejos vivientes nos ofrecen un hermoso espectáculo de sincronía y brillos.',
        'preguntas': [
            {'pregunta': '¿Por qué les llaman peces espejo?', 'respuesta': 'Los particulares peces que encontrarás en este hábitat son conocidos como peces espejo, esto se debe al reflejo que dan sus escamas en un tono plateado, su brillo te sorprenderá.'},
            {'pregunta': '¿Qué comen los peces espejo?', 'respuesta': 'Se trata de un pez carnívoro, que come pequeños pulpos, peces, camarones, y calamares.'},
            {'pregunta': '¿Dónde encontramos a los peces espejo?', 'respuesta': 'La especie Selene brevoortii se distribuye desde las costas de Baja California y el Mar de Cortés hasta el ecuador.'},
        ],
        'especies': [
            {
                'nombre_comun':'Pez espejo, papelillo, jorobada.',
                'nombre_display':'Pez espejo',
                'nombre_cientifico':'Selene brevoortii',
                'conservacion':'Preocupación menor',
                'route':'pez-espejo',
                'eng_route':'pacific-lookdown',
                'datos': [
                    'Su color plateado (pigmentos de guanina) le da a su  cuerpo un efecto espejo que puede modificar para atenuar la luz o hacerla más fuerte y así mejorar su camuflaje.',
                ],
                'generalidades': [
                    'Pez de aguas costeras con cuerpo pentagonal comprimido, color plateado. Llega a medir hasta 25 cm.',
                    'Vive en aguas costeras poco profundas en pequeños bancos cerca del fondo.',
                    'Se alimenta de calamares pequeños, camarones, gusanos poliquetos y peces pequeños.',
                ],
            },
        ]
    },
    { 
        'id':'oceanica',
        'nombre':'OCEÁNICA',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'ocean',
        'description':'Llega hasta el corazón del mar: experimenta la increíble belleza y majestuosidad de la vida oceánica. El hábitat más grande del acuario, con una ventana de más de 13 metros de largo, te dejará sin aliento.',
        'preguntas': [
            {'pregunta': '¿Cuál es la importancia del océano?', 'respuesta': 'El océano y su vida dan forma a las características de la tierra y permiten que la vida en ella sea habitable. Tiene una relación estrecha con el clima, ya que sus aguas absorben una gran cantidad de calor y dióxido de carbono. Además de estos beneficios naturales, nos proporcionan recursos como alimento y energía.'},
            {'pregunta': '¿Qué son las especies pelágicas?', 'respuesta': 'Son especies que se encuentran nadando libremente en el mar y no suelen permanecer cerca de la costa.'},
            {'pregunta': '¿Cuál es la edad del Mar de Cortés?', 'respuesta': 'El magnífico Mar de Cortés se originó hace 5-6 millones de años y es el mar más joven del mundo.'},
        ],
        'especies': [
            {
                'nombre_comun':'Tiburón puntas negras',
                'nombre_display':'Tiburón/puntas negras',
                'nombre_cientifico':'Carcharhinus limbatus',
                'conservacion':'Casi amenazado',
                'route':'tiburon-puntas-negras',
                'eng_route':'blacktip-shark',
                'datos': [
                    'En 2008 se encontró evidencia en DNA de que una hembra fertilizó sus huevos sin ayuda de un macho.',
                    'Saltan por encima del agua dando giros y caen sobre sus espaldas, a veces como una estrategia de alimentación, para golpear bancos de peces.',
                ],
                'generalidades': [
                    'Tiburón de hocico más o menos largo y puntiagudo al final, ojos pequeños. Mide entre 1.20 y 1.94m, siendo los machos más grandes que las hembras.',
                    'Habitan tanto en la costa como en mar adentro por todo el planeta.',
                    'Es un cazador activo de peces, pequeños tiburones, rayas, cefalópodos y crustáceos.',
                ],
            },
            {
                'nombre_comun':'Tiburón colorado',
                'nombre_cientifico':'Carcharhinus altimus',
                'conservacion':'Casi amenazado',
                'route':'tiburon-colotrado',
                'eng_route':'bignose-shark',
                'datos': [
                    'Durante el día, nada en aguas profundas pero durante la noche se mueve más cerca de la superficie.',
                    'Llega a medir casi 3 metros y puede llegar a vivir unos 21 años.',
                ],
                'generalidades': [
                    'Cuerpo robusto, hocico largo, ancho y ligeramente puntiagudo; ojos redondeados; coloración gris, blancuzca hacia la parte ventral.',
                    'Se alimenta de peces, mantarrayas y sepias, pero también puede comerse a otros tiburones.',
                    'Suele frecuentar relieves marinos accidentados: deniveles y fracturas del suelo.',
                ],
            },
            {
                'nombre_comun':'Tiburón aleta de cartón',
                'nombre_display':'Tiburón aleta/de cartón',
                'nombre_cientifico':'Carcharinus plumbeus',
                'conservacion':'Vulnerable ',
                'route':'tiburon-trozo',
                'eng_route':'sandbar-shark',
                'datos': [
                    'Las hembras adultas suelen desarrollar una capa de piel más gruesa que los machos. Esta piel engrosada sirve como protección en sus rituales de apareamiento y en las condiciones difíciles del entorno rocoso y coralino donde viven.',
                ],
                'generalidades': [
                    'Cuerpo robusto con nariz redondeada y ojos pequeños, coloración gris-café y blanco en el vientre.',
                    'Se le encuentra tanto en costa como en altamar, con mayor frecuencia en bahías, desembocadura de ríos y puertos evitando playas arenosas, arrecifes de coral y fondos rocosos.',
                    'Se alimenta de peces, tiburones pequeños, pulpo, calamar, camarón, rayas, caracoles y babosas marinas, los más jóvenes prefieren cangrejo azul y camarón.',
                ],
            },
        ]
    },
    { 
        'id':'migracion',
        'nombre':'MIGRACIÓN',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'migration',
        'description':'Sé testigo de las increíbles travesías de los grandes y pequeños viajeros que llegan al Mar de Cortés.',
        'preguntas': [
            {'pregunta': '¿Qué es la migración?', 'respuesta': 'Es el desplazamiento de una especie de una zona a otra por periodos definidos. Puede ocurrir por distintos motivos, entre los que se destacan la necesidad de sitios propicios para el descanso, alimentación y reproducción.'},
            {'pregunta': '¿Qué características hacen que el Mar de Cortés sea un lugar ideal para especies migratorias?', 'respuesta': 'Periódicamente, las aguas del Mar de Cortés se vuelven muy ricas en nutrientes, que son base de la alimentación de peces de todos los tamaños. Además, algunas bahías e islas son zonas seguras para muchas especies de fauna.'},
            {'pregunta': '¿Qué especies marinas migratorias podemos encontrar en el Mar de Cortés?', 'respuesta': 'Entre la fauna migratoria del Mar de Cortés se encuentran: la ballena gris, tiburón ballena, tortuga golfina, tortuga verde del Pacífico, tortuga carey, calamar de Humboldt, cachalote y el chorlo nevado.'},
        ],
        'especies': [
            {
                'nombre_comun':'Pargo rojo',
                'nombre_cientifico':'Lutjanus colorado',
                'conservacion':'Preocupación menor',
                'route':'pargo-rojo',
                'eng_route':'colorado-snapper',
                'datos': [
                    'Los huevos fertilizados son pelágicos, esféricos, transparentes y con una sola gota de aceite.',
                    'La cantidad y diversidad de presas del pargo rojo  aumentan en el verano.',
                ],
                'generalidades': [
                    'Cuerpo alargado color rojo oscuro que gradualmente pasa a rosa en el vientre; aletas de color rojo oscuro.',
                    'Los adultos habitan en áreas de arrecifes costeros sobre fondos duros.',
                    'Su dieta consiste en invertebrados y peces.',
                ],
            },
            {
                'nombre_comun':'Pargo flamenco, lunarejo o p. de la Mancha',
                'nombre_display':'Pargo flamenco',
                'nombre_cientifico':'Lutjanus guttatus',
                'conservacion':'Preocupación menor',
                'route':'pargo-flamenco',
                'eng_route':'spotted-rose-snapper',
                'datos': [
                    'El más largo que se ha encontrado medía 80 cm y  el más pesado 1.3kg.',
                ],
                'generalidades': [
                    'Pez color rojo pálido o rosa a amarillento, plateado, con bandas de color verde dorado a café. Una mancha negra muy distintiva o grisácea por detrás.',
                    'Los adultos se encuentran sobre fondos duros en áreas de arrecifes costeros y los juveniles habitan estuarios y desembocaduras de ríos.',
                    'Se alimenta de invertebrados y peces.',
                ],
            },
            {
                'nombre_comun':'Pargo prieto, p. negro, p. dientón',
                'nombre_display':'Pargo prieto',
                'nombre_cientifico':'Lutjanus novemfasciatus',
                'conservacion':'Preocupación menor',
                'route':'pargo-prieto',
                'eng_route':'pacific-dog-snapper',
                'datos': [
                    'Es el pargo más grande del Pacífico.',
                ],
                'generalidades': [
                    'Pez de hocico puntiagudo, de color café oliva oscuro a rojo cobrizo en dorso y a los lados, barras de tono café cenizo y blanco plateado en el vientre.',
                    'Los adultos habitan en los arrecifes rocosos; los Juveniles, en estuarios con manglares y desembocaduras de ríos.',
                    'Se alimenta de grandes invertebrados y peces.',
                ],
            },
        ]
    },
    { 
        'id':'la-cueva',
        'nombre':'LA CUEVA',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'the-grotto',
        'description':'Chicos y grandes podrán ver de cerca a los peces de la cueva al asomarse en la cápsula de este hábitat, y de paso sacar una divertida fotografía.',
        'preguntas': [
            {'pregunta': '¿Qué son las morenas?', 'respuesta': 'Son peces de cuerpo alargado y hábitos nocturnos, que durante el día prefieren descansar entre grietas y cuevas.'},
            {'pregunta': '¿Son peligrosas?', 'respuesta': 'Contrario a lo que se piensa son peces tranquilos, que prefieren permanecer en refugios, únicamente atacan si se sienten amenazadas, por eso es importante que respetemos sus espacios.'},
            {'pregunta': '¿De qué se alimentan las morenas?', 'respuesta': 'Su alimento favorito son los crustáceos y peces de menor tamaño que ellas, no tienen problema con alimentarse por la noche, ya que cuentan con un excelente olfato que les permite encontrar su comida.'},
        ],
        'especies': [
            {
                'nombre_comun':'Tieso tigre o anguila manchada',
                'nombre_display':'Tieso tigre',
                'nombre_cientifico':'Myrichthys tigrinus',
                'conservacion':'Preocupación menor',
                'route':'tieso-tigre',
                'eng_route':'spotted-snake-eel',
                'datos': [
                    'Tiene cuatro narinas, las dos primeras, externas, son en forma de tubo y le son muy útiles para oler a sus presas.',
                    'Puede llegar a medir 74 cm.',
                ],
                'generalidades': [
                    'Pez de cuerpo cilíndrico y alargado de color crema con manchas negras ovaladas. Garganta y barilla manchadas. Su cola mide más de la mitad de su cuerpo. No tiene escamas.',
                    'Come camarones, cangrejos, gusanos, peces, también moluscos como pulpos y calamares.',
                    'Vive en fondos arenosos y lodosos, es común verla buscando presas bajo rocas o en grietas.',
                ],
            },
            {
                'nombre_comun':'Morena verde',
                'nombre_cientifico':'Gymnothorax castaneus',
                'conservacion':'Preocupación menor',
                'route':'morena-verde',
                'eng_route':'panamic-green',
                'datos': [
                    'Su vista no es muy buena pero tiene muy buen sentido del olfato que le ayuda a ubicar y emboscar a sus presas.',
                    'Está cubierta de moco amarillo y espeso que la protege de las rocas entre las que vive.',
                ],
                'generalidades': [
                    'Es un pez de cuerpo alargado que se estrecha hacia una cola redondeada. Su color puede ser de verde a café. Tiene  cabeza grande y ojos pequeños.',
                    'Sus aletas anales y dorsales están cubiertas de piel y están fusionadas con la cola, lo que le da apariencia de listón o serpiente. Pueden llegar a medir metro y medio.',
                    'Come peces y crustáceos.',
                ],
            },
            {
                'nombre_comun':'Morena enmascarada o mapache',
                'nombre_display':'Morena enmascarada',
                'nombre_cientifico':'Gymnothorax panamensis',
                'conservacion':'Least concern',
                'route':'morena-enmascarada',
                'eng_route':'masked-moray',
                'datos': [
                    'Abre y cierra constantemente la boca para poder respirar.',
                    'La forma de su cuerpo y la falta de aletas y escamasles permiten moverse rápidamente por las grietas rocosas.',
                    'Las morenas tienen 400% más probabilidades de supervivencia que el resto de los peces porque viven ocultas entre las rocas.',
                ],
                'generalidades': [
                    'Cabeza grande, hocico corto, dientes puntiagudos. Es de color café o café-amarillento en la cabeza. Sus ojos están  rodeados por un amplio anillo café oscuro y los poros en las mandíbulas rodeados de blanco.',
                    'Habita en fondos rocosos, ricos en grietas y hábitats de arrecifes.',
                    'Se alimenta de peces y crustáceos como cangrejos, langostas y camarones.',
                ],
            },
        ]
     },
    {
        'id':'salon-de-las-medusas',
        'nombre':'SALÓN DE LAS MEDUSAS',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'jellyfish-hall',
        'description':'Ven e inspirarte con el bello nado de las medusas, en una atmósfera mágica que hemos creado para ellas y para ti.',
        'preguntas': [
            {'pregunta': '¿Qué es una medusa?', 'respuesta': 'Son animales invertebrados (que no tienen huesos), que pertenecen al grupo de los cnidarios como los corales y las anémonas. Son conocidas por sus abundantes tentáculos, que usan para defenderse y capturar presas.'},
            {'pregunta': '¿Cómo se mueven?', 'respuesta': 'El característico nado de las medusas se debe a su capacidad de tensar su cuerpo para impulsarse en el agua, también suelen moverse aprovechando las corrientes marinas.'},
            {'pregunta': '¿Desde cuándo existen las medusas?', 'respuesta': 'Estos asombrosos animales existen desde hace más de 600 millones de años, mucho antes que los dinosaurios, por lo que son conocidos como fósiles vivientes.'},
        ],
        'especies': [
            {
                'nombre_comun':'Medusa luna',
                'nombre_cientifico':'Aurelia aurita',
                'conservacion':'No evaluado',
                'route':'medusa-luna',
                'eng_route':'moon-jellyfish',
                'datos': [
                    'Sus primeros años de vida son pólipos en suelos y rocas y luego se convierten en medusas móviles.',
                    'Puede ser una especie invasora cuando se reproduce exageradamente debido a la temperatura o la contaminación.',
                    'A partir de ellas se desarrolló una proteína fluorescente que se usa en la investigación biotecnológica.',
                ],
                'generalidades': [
                    'Es un invertebrado marino que en su etapa de medusa de nado libre tiene forma de campana y tentáculos, en los cuales suelen tener células urticantes.',
                    'Se alimenta de plancton, moluscos, huevos de pescado, peces pequeños y medusas más chicas.',
                ],
            },
            {
                'nombre_comun':'Medusa bola de cañón',
                'nombre_display':'Medusa bola/de cañón',
                'nombre_cientifico':'Stomolophus meleagris',
                'conservacion':'No evaluado',
                'route':'medusa-bola-canon',
                'eng_route':'cannonball-jellyfish',
                'datos': [
                    'Posee órganos sensores de luz (rhopalia y estatocistos) que le permite saber si va a la superficie o si va hacia lo profundo.',
                    'Cuando se siente amenazada secreta un moco tóxico que daña a los peces pequeños y ahuyenta a depredadores.',
                    'Se le pesca mucho en el norte de Sonora.',
                ],
                'generalidades': [
                    'Esta medusa es de color azulado o gris en aguas del Pacífico. Tiene 16 brazos orales blancos cortos y ramificados.',
                    'Se alimenta de larvas de peces, crustáceos,  moluscos y huevos de peces.',
                ],
            },
            {
                'nombre_comun':'Medusa de rayas moradas',
                'nombre_display':'Medusa de rayas/moradas',
                'nombre_cientifico':'Chrysaora colorata',
                'conservacion':'No evaluado',
                'route':'medusa-rayas-moradas',
                'eng_route':'purple-striped-jelly',
                'datos': [
                    'Los juveniles de cangrejo de género Cáncer se asocian con esta medusa pues se alimentan de los parásitos que la dañan, a veces incluso viven dentro de ella.',
                    'La campana pulsa para moverse en distancias cortas, pero para ir más lejos, la medusa aprovecha las corrientes marinas.',
                ],
                'generalidades': [
                    'Cuando es adulta es de color blanco plateado con bandas de púrpura intenso. Las medusas jóvenes son de color rosa con tentáculos de color rojo oscuro. Todas tienen cuatro brazos orales en espiral.',
                    'Puede llegar a medir un metro de diámetro.',
                    'Se alimenta de zooplancton.',
                ],
            },
        ]
    },
    {
        'id':'cardumen',
        'nombre':'CARDÚMEN',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'shoals',
        'description':'No te pierdas la sensación de tener sobre la cabeza un remolino de peces, un vórtice de vida.',
        'preguntas': [
            {'pregunta': '¿Qué es un cardumen?', 'respuesta': 'Conocemos como cardumen a los grupos de peces que nadan y se mueven juntos.'},
            {'pregunta': '¿Por qué se forman los cardúmenes?', 'respuesta': 'El agruparse así les trae algunos beneficios, por ejemplo: defenderse de depredadores, facilidad de obtener alimento y encontrar pareja.'},
            {'pregunta': '¿Cómo se guían los peces dentro del cardumen?', 'respuesta': 'Además de apoyarse de su visión, los peces cuentan con un órgano de sentido llamado línea lateral, que les permite detectar los cambios de presión, vibraciones y movimientos a su alrededor cuando algún pez cambia de dirección.'},
        ],
        'especies': [
            {
                'nombre_comun':'Sardina de California',
                'nombre_display':'Sardina/de California',
                'nombre_cientifico':'Sardinops sagax',
                'conservacion':'Preocupación menor',
                'route':'sardina-california',
                'eng_route':'californian-pilchard',
                'datos': [
                    'Forman grandes cardúmenes de cientos de miles de millones de individuos. El movimiento rápido y coordinado de estos grupos son una adaptación para escapar de sus depredadores.',
                    'La sardina es migratoria y puede viajar distancias de 1000km en un año entre sus hábitats de alimentación y desove.',
                    'Son un recurso pesquero muy importante en México.',
                ],
                'generalidades': [
                    'La sardina de california Tiene un cuerpo cilíndrico y delgado de unos 20 cm de largo. Tiene el dorso verde azul y los lados blancos con 1-3 series de manchas oscuras.',
                    'Se alimenta de plancton.',
                ],
            },
        ]
    },

    {
        'id':'la-ventana',
        'nombre':'LA VENTANA',
        'habitat':'mar',
        'eng_carpet':'sea',
        'eng_link':'the-window',
        'description':'Echa un último vistazo al gran hábitat oceánico a través de nuestra ventana y pesca algunas ideas de cómo podemos ayudar a conservar nuestros océanos.',
        'preguntas': [
            {'pregunta': '¿Qué importancia tiene el Mar de Cortés?', 'respuesta': 'El Mar de Cortés o Golfo de California es una zona que, por su clima y disponibilidad de recursos se ha convertido en el lugar preferido de muchos animales para alimentarse y reproducirse. El explorador Jacques Cousteau lo nombró "el acuario del mundo".'},
            {'pregunta': '¿Por qué se busca la conservación de nuestros océanos?', 'respuesta': 'Muchos programas de conservación se han enfocado en los océanos, porque estos sitios son nuestra principal fuente de oxígeno, ayudan a regular el clima y nos proveen de alimento.'},
            {'pregunta': '¿Qué podemos hacer desde casa para cuidar nuestros mares?', 'respuesta': 'Todos podemos ayudar a conservar estos importantes lugares, con acciones como reducir el uso de plásticos, disminuir el uso de electricidad y evitar dejar basura cuando visitamos la playa. Dentro del Gran Acuario podrás conocer más sobre cómo podemos trabajar juntos para tener un océano más sano y lleno de vida.'},
        ],
        'especies': [
            {
                'nombre_comun':'Tiburón martillo, cornuda común o cachona, morfillo',
                'nombre_display':'Tiburón martillo',
                'nombre_cientifico':'Sphyrna lewini',
                'conservacion':'En peligro crítico de extinción',
                'route':'tiburon-martillo',
                'eng_route':'scalloped-hammerhead',
                'datos': [
                    'Ha sido filmado con una cámara con carnada a 512m de profundidad.',
                    'Forma enormes escuelas de pequeños individuos migratorios se mueven en el verano en ciertas áreas. También existen poblaciones de residentes permanentes.',
                ],
                'generalidades': [
                    'Tiburón de cuerpo grande y moderadamente delgado; cabeza ancha con su característico "martillo". Es de color gris claro o marrón grisáceo por encima, sombreado a blanco por debajo.',
                    'Se alimenta principalmente  de peces, calamares y pulpos, también come langostas, camarones, cangrejos, así como otros tiburones y rayas.',
                ],
            },
            {
                'nombre_comun':'Pez gallo',
                'nombre_cientifico':'Nematistius pectoralis',
                'conservacion':'No evaluado',
                'route':'pez-gallo',
                'eng_route':'roosterfish',
                'datos': [
                    'Se le busca mucho en pesca deportiva. El más grande que han capturado medía 1.63m  y el más pesado casi 52kg.',
                ],
                'generalidades': [
                    'Cuerpo alargado y cabeza puntiaguda. Primera aleta dorsal tiene 7 espinas muy largas que se pliegan en un surco cuando la aleta se baja. Es de color gris azulado con reflejos plateados y barras negras.',
                    'Vive en zonas costeras poco profundas, le gustan las costas arenosas y forman bancos de peces.',
                    'Come peces pequeños.',
                ],
            },
            {
                'nombre_comun':'Pámpano común, plateado o toro',
                'nombre_display':'Pámpano común',
                'nombre_cientifico':'Trachinotus kennedyi',
                'conservacion':'Preocupación menor',
                'route':'pampano-comun',
                'eng_route':'common-pompano',
                'datos': [
                    'Se ha capturado cerca de la desembocadura de ríos y fondos arenosos.',
                    'A pesar de que fue descubierto desde finales de 1800, se sabe muy poco sobre él.',
                    'Trachinotus significa espalda rugosa.',
                ],
                'generalidades': [
                    'Cuerpo corto, boca pequeña, nuca prominente; parte superior de la cabeza y cuerpo oscuro, a veces dorado o ligeramente bronceado, vientre plateado. Tiene una gran mancha negra en la aleta pectoral.',
                    'Habita en aguas pocas poco profundas y los juveniles en estuarios.',
                    'Se alimenta de moluscos, crustáceos y peces pequeños.',
                ],
            },
        ]
    },
];

export const habitatsEng = [
    /* COAST */
    { 
        /* INTERTIDAL */
        'id':'intertidal',
        'nombre':'INTERTIDAL',
        'habitat':'coast',
        'header_class':'intermareal',
        'carpeta':'costa',
        'description':'Dig your hands into a world where land meets the sea. Get to know the components of the intertidal zone and interact with them. Discover their incredible forms and textures!',
        'preguntas': [
            {'pregunta':'What is the intertidal zone?', 'respuesta':'It is the transition zone between the terrestrial environment and the marine world. It is a zone of great dynamism, which in a matter of hours can be flooded or exposed to the air.'},
            {'pregunta':'Who lives in the intertidal zone?', 'respuesta':'Animals adapted to changing environments. These include invertebrates such as snails, crabs, starfish, sea urchins, anemones and small fish.'},
            {'pregunta':'What is the ecological importance of these sites?', 'respuesta':'It provides abundant food for its permanent and temporary inhabitants, such as fish that arrive with the tidal dynamics and some birds. They are also breeding sites for sea turtles, crabs and fish.'},
        ],
        'especies': [
            {
                'nombre_comun':'Large-Banded Blenny',
                'nombre_display':'Large-Banded/Blenny',
                'nombre_cientifico':'Ophioblennius steindachneri',
                'conservacion':'Least concern',
                'route':'large-banded',
                'carpet_route':'borracho-mono',
                'datos': [
                    'It likes to live among the rocks, in the surf zone.',
                    'They are very territorial, vigorously defending their habitat from intruders.',
                    'These fish do not have scales.'
                ],
                'generalidades': [
                    'Cilidrical body, head with fringed tentacles called cirri above their eyes, back of neck and nostril edges.',
                    'Elongated inferior fins.',
                    'It has two incisive, comb-like teeth to feed itself with algae and crustaceans.'
                ],
            },
            {
                'nombre_comun':'Chocolate chip star',
                'nombre_display':'Chocolate/chip star',
                'nombre_cientifico':'Nidorellia armata',
                'conservacion':'Not evaluated',
                'route':'chocolate-star',
                'carpet_route':'estrella',
                'datos': [
                    'It was found as part of an offering at the Major Temple of Tenochtitlan (Close to Mexico City).',
                    'The Starfish Shrimp (Zenopontonia soror) usually is associated with starfish like the Chocolate Chip Starfish: it lives beneath it, adopts the same color to camouflage itself, serves as its protector, and feeds off the mucus secreted by this starfish.',
                ],
                'generalidades': [
                    'Its central disc is thick and inflated, with short and thick arms and dark brown conical thorns that give it its common name. It grows to be up to 6 inches in diameter. It feeds on algae, other echinoderm (sea urchins and cucumbers) and small invertebrates.',
                ],
            },
            {
                'nombre_comun':'Slate pencil urchin',
                'nombre_display':'Slate/pencil urchin',
                'nombre_cientifico':'Eucidaris tribuloides',
                'conservacion':'Not evaluated',
                'route':'slate-pencil-urchin',
                'carpet_route':'erizo',
                'datos': [
                    'They use a complex mouth apparatus on the underside of their bellies to feed themselves, which has been called the “Aristotle´s Lantern”, in honor of the wise Greek author that described it in his book, “History of the Animals”.',
                ],
                'generalidades': [
                    'The Slate Pencil Urchin is an echinoderm: a boneless animal with “thorny skin” and an exoskeleton (external skeleton). It’s of a light brown or reddish color, with scarce thick, brown, cilindrical thorns.',
                ],
            },
            {
                'nombre_comun':'California sea hare',
                'nombre_display':'California/sea hare',
                'nombre_cientifico':'Aplysia californica',
                'conservacion':'Not evaluated',
                'route':'california-sea-hare',
                'carpet_route':'liebre-marina',
                'datos': [
                    'It is called a Sea Hare because it has two tentacles behind its eyes that look like hare ears.',
                    'It can release an intense purple ink that smells like cedarwood when it feels threatened.'
                ],
                'generalidades': [
                    'A variable-colored, reddish-brown or greenish mollusk, with a fleshy and delicate texture. It has four tentacles on its head and two wing-like folds on its back that cover its gills. It has a muscular foot on its underside, that it uses to move itself.',
                    'Juveniles live at around 59 feet from the surface, while adults prefer the intertidal zone.',
                    'It feeds on red algae, sea lettuce and sea grasses.'
                ],
            },
            {
                'nombre_comun':'Largemouth blenny',
                'nombre_cientifico':'Labrisomus xanti',
                'conservacion':'Least concern',
                'route':'largemouth-blenny',
                'carpet_route':'labrisomido',
                'datos': [
                    'Due to the increase in water temperature as a result of global warming, its distribution is changing and has started to show up further north, up to California, U.S.A.',
                    'Labrosimid blennies such as the Largemouth are small and flexible to move among the rocks and find refuge in seashells and crevices.',
                ],
                'generalidades': [
                    'It is of greenish-brown color with dark lateral stripes, a black spot on the back fin and small white spots on its head and body. It has fringed tentacles (cirri) above each eye and on the back of its neck, and a big mouth.',
                    'It lives in shallow waters and on rocks covered with algae.',
                    'It feeds on floor crustaceans such as small crabs.',
                ],
            },
            {
                'nombre_comun':'Sonora blenny',
                'nombre_cientifico':'Malacoctenus gigas',
                'conservacion':'Least concern',
                'route':'sonora-blenny',
                'carpet_route':'blenio',
                'datos': [
                    'It is only found in the Gulf of California coast. That is to say, that it is endemic to this zone.',
                    'It tolerates temperatures of up to 50°F.',
                ],
                'generalidades': [
                    'It has a thin head, pointy snout and small nose. Its back is green and its chest and belly are white, with four or five dark brown stripes on its sides. It has a pair of tentacles (cirri) on the back of its neck and above its eyes.',
                    'During mating season, males have dark fins and bright orange sides with blue spots.',
                    'It feeds on small crustaceans.',
                    'It lives in shallow waters, coral and rocky reefs, among marine algae.',
                ],
            },
        ]
    },
    { 
        /* MANGROVE */
        'id':'mangrove',
        'nombre':'MANGROVE',
        'habitat':'coast',
        'header_class':'manglar',
        'carpeta':'costa',
        'description':'Take a peek at the roots of our live mangroves. Fish, crustaceans and many other animals live in the amphibian forest. The mangrove provides shelter for all of us, and here we will explain why.',
        'preguntas': [
            {'pregunta':'What are mangroves?', 'respuesta':'Amphibious forests on tropical coastal zones, that grow in areas flooded by the tides. They mainly comprise of mangrove trees.'},
            {'pregunta':'Why are mangroves important?', 'respuesta':'Because it is where different land and water species interact. They are breeding grounds and shelters for many animals during their first stages of life. Furthermore, they provide nutrients to marine ecosystems, filter the water, and serve as a buffer against the impact of weather phenomena such as hurricanes.'},
            {'pregunta':'What mangrove species can we find in the Sea of Cortez?', 'respuesta':'Red Mangrove (Rhizophora mangle), White Mangrove (Laguncularia racemosa), Black Mangrove (Avicennia germinans) and Buttonwood (Conocarpus erectus).'},
        ],
        'especies': [
            {
                'nombre_comun':'Black mangrove',
                'nombre_cientifico':'Avicennia germinans',
                'conservacion':'Least concern',
                'route':'black-mangrove',
                'carpet_route':'negro',
                'datos': [
                    'This mangrove is the most tolerant of seawater.',
                    'Its leaves are usually covered with dust or salt cristals, because they have glands that eliminate the excess salt from the water it absorbs.'
                ],
                'generalidades': [
                    'Despite its name, it is of a light color, with leaves of a pale gray on its underside and brilliant grey on the front side. Its arched roots are used to breathe, while exposed during the low tide.',
                    'Its flowers are white or yellow, and bloom especially during the rainy season.',
                    'It can survive in waters without oxygen, in muddy or flooded areas.'
                ]
            },
            {
                'nombre_comun':'White mangrove',
                'nombre_cientifico':'Laguncularia racemosa',
                'conservacion':'Least concern',
                'route':'white-mangrove',
                'carpet_route':'blanco',
                'datos': [
                    'Sometimes, it is viviparous. This means that its seeds germinate within the fruit, while it is still on the tree.',
                    'Its bark has substances called tannins, that are used for leather tanning.',
                    'It has been traditionally used to treat dysentery and health problems, because of its astringent and tonic properties.'
                ],
                'generalidades': [
                    'It is a shrub with a greyish-brown, ridged, rugged bark. Its opposing leaves are yellowish-green, rounded, fleshy, without visible veins, and they have a couple of bulges (glands).',
                    'Its whitish-green flowers bloom in spikes, and appear from July to October. Its fruit has an almond form.',
                    'It lives in estuaries and coves, on seldom flood-prone sandy or clay grounds. It is vulnerable to wind, due to its superficial roots.'
                ]
            },
            {
                'nombre_comun':'Red mangrove',
                'nombre_cientifico':'Rhizophora mangle',
                'conservacion':'Least concern',
                'route':'red-mangrove',
                'carpet_route':'rojo',
                'datos': [
                    'Sometimes this mangrove strikes up a mutually beneficial relationship with sea sponges, that settle their roots to exchange nutrients.',
                    'It is used as firewood and to build furniture and musical instruments. Its bark produces a blue ink that is used as dye. It is also used as a traditional medicinal remedy for marine animal bites, fever and other health problems.'
                ],
                'generalidades': [
                    'This tree has wader roots. Its bark is smooth, gray, from a redish to a brown color. It maintains the dark green color of its leaves year-around.',
                    'The color of its flowers ranges from a greenish-yellow to beige. Its brownish-red fruit has the form of a berry. A spear-like seed is formed and floats inside the fruit. The fruit grows with the seed already germinated on the tree.',
                    'It likes to live “with its feet in the water” so to speak, in flooded grounds with variable salinity.'
                ]
            },
            {
                'nombre_comun':'Mazatlan sole',
                'nombre_cientifico':'Achirus mazatlanus',
                'conservacion':'Least concern',
                'route':'mazatlan-sole',
                'carpet_route':'lenguado',
                'datos': [
                    'The word Achirus means “without hands”, and Mazatlanus probably alludes to the place (Mazatlán) where this specimen, described by the Austrian Zoologist Franz Steindachner in 1869, was caught.'
                ],
                'generalidades': [
                    'Oval-shaped body with both eyes on its right side, and a small, arched mouth. Of a dark grey or brownish color. It has 8 dark lines on the side of its eyes, and groups of dispersed, black strands.',
                    'It lives in coastal waters at a depth of under 65 feet, swims into coastal lagoons and freshwater.',
                    'It feeds on crustaceans, small fish, bristle worms and organic waste.'
                ]
            },
            {
                'nombre_comun':'Whiteleg shrimp',
                'nombre_cientifico':'Litopaneaus vannamei',
                'conservacion':'Not evaluated',
                'route':'whiteleg-shrimp',
                'carpet_route':'camaron',
                'datos': [
                    'It is the most dominant shrimp crop species in the world.',
                    'It has a couple of glands on its antennae with an excretory function, where you could say that it “pee” through its antennae.'
                ],
                'generalidades': [
                    'It can be translucid, bluish or olive green in color, with dark stripes and brownish-red antennules, but it is distinguished by its 10 white legs.',
                    'Its body is elongated and segmented, covered with an exoskeleton. It has thirteen pairs of appendages (like antennae).',
                    'This crustacean feeds on plankton and organic waste.'
                ]
            },
            {
                'nombre_comun':'Common snook',
                'nombre_cientifico':'Centropomus robalito',
                'conservacion':'Least concern.',
                'route':'common-snook',
                'carpet_route':'constantino',
                'datos': [
                    'Of the 12 Centropomus species that exist in the world, all are found in Mexico: 6 in the Atlantic and 6 in the Pacific.',
                    'In the Southwest region of the Gulf of California, they like to feed mainly on shrimp.'
                ],
                'generalidades': [
                    'It is a silver-colored fish with a noticeable dark lateral stripe, elongated and compressed body, with yellow anal and pelvic fins.',
                    'It lives in bays, estuaries and freshwater streams.',
                    'It feeds on fish, crustaceans and mollusks.'
                ]
            },
        ]
    },
    { 
        /* TURTLES */
        'id':'turtles',
        'nombre':'SEA TURTLES',
        'habitat':'coast',
        'header_class':'tortugas',
        'carpeta':'costa',
        'description':'Visit the turtle shelter, where we give temporary shelter for the protection and recovery of these noble reptiles.',
        'preguntas': [
            {'pregunta':'What are turtles?', 'respuesta':'They are reptiles that have inhabited the earth for more than 100 million years. They have large fins and a slender carapace that helps them swim quickly.'},
            {'pregunta':'What do turtles feed on?', 'respuesta':'Their diet may vary according to the species, but among their favorites are jellyfish, crabs and shrimp.'},
            {'pregunta':'What are the most common turtle species in Mazatlan Bay?', 'respuesta':'Olive Ridley turtle (Lepidochelys olivacea), hawksbill turtle (Eretmochelys imbricata), green turtle (Chelonia mydas) and loggerhead turtle (Caretta caretta).'}
        ],
        'especies': [
            {
                'nombre_comun':'Pacific Sergeant Major, Panama Sergeant Major',
                'nombre_display':'Pacific/sergeant major',
                'nombre_cientifico':'Abudefduf troschelii',
                'conservacion':'Least concern',
                'route':'pacific-sergeant-major',
                'carpet_route':'sargento',
                'datos': [
                    'Males of this species have very marked hierarchies among different types of males.',
                    'Their eggs look like purple patches on the rocks.',
                    'Juveniles of this species have been observed cleaning other fish, such as the Striped Red Mullet, Longfin Halfbeak and Needlefish.',
                ],
                'generalidades': [
                    'This fish has large and rough scales on its body and on most part of its head. It´s of a whitish-green or pale silver color, with 5 black stripes on the side and a bright yellow back.',
                    'Adults live in rocky and coral reefs, and swim in large schools in warm waters.',
                    'It feeds on plankton, benthic invertebrates and the algae found on reefs.',
                ],
            },
            {
                'nombre_comun':'Pacific Ridley Sea Turtle',
                'nombre_display':'Pacific ridley/sea turtle',
                'nombre_cientifico':'Lepidochelys olivacea',
                'conservacion':'Vulnerable',
                'route':'pacific-ridley',
                'carpet_route':'golfina',
                'datos': [
                    'Besides solitary nesting, this turtle engages in mass nesting known as an “arribada”, meaning “arrival by sea”, where thousands of turtles go out to nest together.',
                    'It is the most abundant sea turtle on the planet.',
                    'Years ago, fishing and eating it were allowed, but now it´s a protected species.',
                ],
                'generalidades': [
                    'It has a triangular-shaped head, its shell is olive green, round and flat. Its body is from an olive green to a dark gray in color.',
                    'It feeds on fish, mussels and crustaceans, especially shrimp.',
                    'It can reach up to 110 pounds in weight.',
                ],
            },
            {
                'nombre_comun':'Yellowtail Surgeonfish',
                'nombre_cientifico':'Prionurus punctatus',
                'conservacion':'Least concern',
                'route':'yellowtail-surgeonfish',
                'carpet_route':'cochinito',
                'datos': [
                    'It is vegetarian. It eats seasonal algae. As an herbivore, its intestine is longer than carnivore fish, and its stomach is muscular, like a gizzard.',
                    'It behaves diplomatically: it uses its stemmed spine to slap other fish, in order to resolve territorial matters.',
                ],
                'generalidades': [
                    'This compressed, oval-shaped fish has its eyes positioned high on its head. It is grey in color, with small black specks and a bright yellow tail.',
                    'It can be found in shallow reefs, in schools (groups of fish).',
                    'It feeds on algae found on rocks.',
                ],
            },
        ],
    },
    {
        /* RAY BAY */
        'id':'ray-bay',
        'nombre':'RAY BAY',
        'habitat':'coast',
        'header_class':'bahia-rayas',
        'carpeta':'costa',
        'description':'Live unforgettable moments at our Ray Bay, where you can interact with and learn from these unique and marvelous fish.',
        'preguntas': [
            {'pregunta':'What are Rajiforms (Rays)?', 'respuesta':'They are fish with a cartilaginous skeleton, closely related to sharks. What differentiates them from sharks is their flat body, and gills located on their body´s underside.'},
            {'pregunta':'Where do rays live?', 'respuesta':'In areas close to the coast and in open sea. They live along different depths and are commonly found close to the seabed.'},
            {'pregunta':'What do rays eat?', 'respuesta':'They prefer shrimp, clams, bristle worms, fish, snails and octopus.'}
        ],
        'especies': [
            {
                'nombre_comun':'California Butterfly Ray',
                'nombre_display':'California/Butterfly Ray',
                'nombre_cientifico':'Gymnura marmorata',
                'conservacion':'Near threatened',
                'route':'butterfly-ray',
                'carpet_route':'raya-mariposa',
                'datos': [
                    'It has a small venum apparatus to defend itself.',
                    'The female keeps the eggs in her body, the embroyos feed off the yolk, and later feed off their mother.',
                    'The female is twice as large as the male.',
                ],
                'generalidades': [
                    'This ray´s disc is wider than it is long. It is brown in color with pale black specks. Its tail is thin and extremely short. Its snout is short, its mouth is arched, its eyes and spiracle are on its back, and its back surface is smooth.',
                    'It lives in bays, canals and sandy or muddy estuaries.',
                    'It feeds on crustaceans and small fish.',
                ],
            },
            {
                'nombre_comun':'Longtail Stingray',
                'nombre_display':'Longtail/stingray',
                'nombre_cientifico':'Hypanus longus',
                'conservacion':'Vulnerable',
                'route':'longtail-stingray',
                'carpet_route':'raya-latigo',
                'datos': [
                    'They spend most of their time in deep waters, but females, which are larger than males, migrate to shallow waters in the Spring to spawn live young.',
                ],
                'generalidades': [
                    'It can measure up to a little over three feet. Its disc has a comet form, long, pointy snout, large eyes, grey, reddish-brown or olive grey back, and a white belly.',
                    'This cartilage-skeleton fish feeds on mollusks, crabs and fish.',
                ],
            },
            {
                'nombre_comun':'Speckled guitarfish',
                'nombre_display':'Speckled/guitarfish',
                'nombre_cientifico':'Pseudobatos glaucostigma',
                'conservacion':'Vulnerable',
                'route':'speckled-guitarfish',
                'carpet_route':'punteada',
                'datos': [
                    'It has small thorns on its back, but is soft to the touch.',
                    'It’s the only guitar species with slate gray specks on its head and top part of its body, so it is easy to recognize.',
                ],
                'generalidades': [
                    'Its body is similar to a shark´s, and has a large, triangular-shaped head. They are brown with slate grey specks.',
                    'They swim near the bottom of the seabed and form large schools in coastal waters, over sandy and muddy areas.',
                    'Adults feed on squat lobsters and crabs. Juveniles prefer smaller crustaceans called scuds.',
                ],
            },
            {
                'nombre_comun':'Round stingray',
                'nombre_cientifico':'Urobatis halleri',
                'conservacion':'Least concern',
                'route':'round-stingray',
                'carpet_route':'raya-redonda',
                'datos': [
                    'Females emit an electric field that males can perceive.',
                    'Mating lasts for approximately 5 minutes.',
                    'They usually spawn 6 live offspring and juveniles remain close to the water´s edge, where there are fewer predators.',
                ],
                'generalidades': [
                    'It is a round disc ray, slightly wider than it is long; smooth light brown or reddish-brown skin, with dense, dark brown spots.',
                    'It lives in sandy and muddy ocean and swamp floors.',
                    'It feeds on invertebrates on the seabed and small fish.',
                ],
            },
            {
                'nombre_comun':'Golden Cownose Ray',
                'nombre_display':'Golden/Cownose Ray',
                'nombre_cientifico':'Rhinoptera steindachneri',
                'conservacion':'Near threatened',
                'route':'golden-cownose-ray',
                'carpet_route':'chucho-dorado',
                'datos': [
                    'It frequently forms schools, and sometimes associates with another ray called Hook-Billed Kite.',
                    'Only the left ovary and uterus are functional in females. In fact, they have few offspring, just one embroyo per female a year.',
                ],
                'generalidades': [
                    'It has a diamond shape, with its head noticeably protruding from its disk; its tail is slim, with smooth skin. It is of a pale, yellowish-brown color, with a white belly and black tail.',
                    'It lives on soft seabeds, close to rocky or coral reefs and coastal lagoons, and occasionally, close to the surface. It can jump out of the water.',
                    'It feeds on crustaceans on the seabed and mollusks.',
                ],
            },
            {
                'nombre_comun':'Blotched Stingray',
                'nombre_cientifico':'Urotrygon chilensis',
                'conservacion':'Near threatened',
                'route':'blotched-tingray',
                'carpet_route':'raya-moteada',
                'datos': [
                    'It´s an ovoviviparous species, meaning that it breeds its offspring in eggs, which it keeps inside its body until they are born.'
                ],
                'generalidades': [
                    'It has an oval-shaped body, with big eyes and a slim tail with a thorn. It is distributed from the Gulf of California to Peru and Chile, at a depth of between 3 and 197 ft. It feeds mainly on small crustaceans, mollusks, bristle worms and small fish.'
                ],
            },
        ]
    },
    {
        /* SURF ZONE */
        'id':'surf-zone',
        'nombre':'SURF ZONE',
        'habitat':'coast',
        'header_class':'rompiente',
        'carpeta':'costa',
        'description':'Feel the power of the sea and be amazed with the water´s immense strength. Life also blooms under the waves!',
        'preguntas': [
            {'pregunta':'What is the surf zone?', 'respuesta':'It´s the zone where breaking waves occur on the coast, where the waves´ energy shapes its shores.'},
            {'pregunta':'Which animals live in the surf zone?', 'respuesta':'A great variety of marine fauna is found in this zone, such as sea snails, clams, barnacles, goose neck barnacles, chitons, sea urchins and starfish.'},
            {'pregunta':'What is the environmental importance of the surf zone?', 'respuesta':'It is home to a wide range of marine species, and a shelter zone for the larvae of some species of fish and crustaceans.'}
        ],
        'especies': [
            {
                'nombre_comun':'Sea Cockroach',
                'nombre_cientifico':'Chitón articulatus',
                'conservacion':'Data deficient',
                'route':'sea-cockroach',
                'carpet_route':'cucaracha-mar',
                'datos': [
                    'It is a basibont, meaning that other living creatures live on top of it.',
                    'It´s the Sea Snail´s (Plicopurpura pansa, protected species) favorite food, but is also served at some restaurants.',
                ],
                'generalidades': [
                    'It has an oval shape with a segmented armor formed by hard plates that protect this organism. Underneath, it has a muscular foot and bivalves that help it stick to the rocks where it lives. They are of an olive green to a brown color.',
                    'They are frequently found in surf zones.',
                    'They feed on organic waste, even though for a long time it was thought that they were herbivores.',
                ],
            },
            {
                'nombre_comun':'Bumphead Damselfish',
                'nombre_cientifico':'Microspanthodon bairdii',
                'conservacion':'Least concern',
                'route':'bumphead-damselfish',
                'carpet_route':'damisela-cabezona',
                'datos': [
                    'They deposit their eggs in crevices among the rocks in strong tidal zones, where males protect and aerate them.',
                ],
                'generalidades': [
                    'Adults have a hunchback, and can be of a gray, dark or slate brown color, sometimes with a white or yellowish head. Juveniles are of a bright blue color on top, and orange on their underside.',
                    'It lives in rocky coastal reefs, by the surf zone and tides.',
                    'If feeds mainly on benthic microalgae, crustaceans and bristle worms.',
                ],
            },
            {
                'nombre_comun':'Giant Damselfish',
                'nombre_cientifico':'Microspathodon dorsalis',
                'conservacion':'Least concern',
                'route':'giant-damselfish',
                'carpet_route':'damisela-gigante',
                'datos': [
                    'It defends its feeding and breeding territory by driving away fish and scuba divers that get too close.',
                    'Its eggs adhere to the substrate, where males protect and aerate them until the offspring are born.',
                ],
                'generalidades': [
                    'Its body is thick and flat, of a dark greyish-blue or black color. It grows up to 12 inches.',
                    'It feeds on algae, bristle worms, crustaceans and parasites.',
                ],
            },
            {
                'nombre_comun':'California Mussel',
                'nombre_cientifico':'Mytilus californianus',
                'conservacion':'Not evaluated',
                'route':'california-mussel',
                'carpet_route':'mejillon-californiano',
                'datos': [
                    'Mussel beds trap water, mud and matter that offer food and shelter to an incredible variety of animals and plants.',
                    'Mussels can be dangerous for human consumption when there are harmful or excessive algae growth, such as red tides. They act as filters, and feed on dinoflagellates that form these tides and that can produce toxins.',
                ],
                'generalidades': [
                    'Its shell is of a bluish-black color, often with white eroded valves and darker on its edges. The shell is pointy at its back-end.',
                    'It lives on rocks that are exposed to waves, and on dock pilings. It´s abundant in the surf zone.',
                    'It feeds itself by filtering water while trapping organic matter and plankton.',
                ],
            },
            {
                'nombre_comun':'Rock Oyster',
                'nombre_cientifico':'Striostrea prismatica',
                'conservacion':'Not evaluated',
                'route':'rock-oyster',
                'carpet_route':'ostion-roca',
                'datos': [
                    'Oysters are excellent indicators of marine environmental health. They act as biomonitors, because they filter and accumulate contaminants.',
                ],
                'generalidades': [
                    'Its shell is either oval or elongated, heavy and thick. Its exterior is of a brownish-purple color and its interior is irridescent white with brown patches.',
                    'It lives in the rocky zone where the ocean tide rises and falls.',
                    'It feeds on phytoplankton and organic matter.',
                ],
            },
            {
                'nombre_comun':'White Sea/Urchin',
                'nombre_display':'White Sea Urchin',
                'nombre_cientifico':'Tripneustes depressus',
                'conservacion':'Not evaluated',
                'route':'white-sea-urchin',
                'carpet_route':'erizo',
                'datos': [
                    'It has been proven that the coelomic fluid of this sea urchin has an antiviral effect against rabies and pseudorabies.',
                    'It grows much faster than other sea urchins.',
                    'It has photoreceptor cells, and it has been suggested that the urchin´s entire body functions as a large compound eye.',
                ],
                'generalidades': [
                    'Compressed, globe-like body, with a reddish-brown base and thorns with the same base color, and is yellowish-white from its middle to its point.',
                    'It lives in rocky seabeds covered by algae, seagrass meadows and coral reef plains.',
                    'It is most commonly found in shallow waters. It feeds on algae and some invertebrates.',
                ],
            },
        ]
    },

    /* LAND */
    {
        /* TERRARIUM */
        'id':'terrarium',
        'nombre':'TERRARIUM',
        'habitat':'land',
        'header_class':'terrario',
        'carpeta':'tierra',
        'description':'Discover fascinating creatures with scales and slippery skin. Learn about the key milestones that led to living beings coming out from the water and adapting to land survival. We´re sure you´ll love it!',
        'preguntas': [
            {'pregunta':'What is Herpetofauna?', 'respuesta':'It is a group of amphibian animals and reptiles known for their hairless skin, and for being “cold blooded”; in other words, they don´t regulate their body temperature (like us) and depend on the environment to maintain their ideal body temperature. Mexico is the second country in the world with the most Herpetofauna.'},
            {'pregunta':'What are amphibians?', 'respuesta':'They´re animals with smooth skin and no scales, feathers, or hair. They live close to the water, since their eggs depend on humidity for their development. Frogs, toads, salamanders and Axolotl salamanders belong to this group.'},
            {'pregunta':' What are reptiles?', 'respuesta':'They´re animals known for their scaly skin, and bodies that allow them to move about in dry and extreme environments. They were the first to become independent from water for their reproduction, since their hard eggshells protect them from losing water. Animals such as snakes, turtles, lizzards and iguanas are part of this group.'},
        ],
        'especies': [
            {
                'nombre_comun':'Sinaloa toad',
                'nombre_cientifico':'Incilius mazatlenensis',
                'conservacion':'Least concern',
                'route':'sinaloa-toad',
                'carpet_route':'sapito-mazatlan',
                'datos': [
                    'This species is only found in Mexico.',
                    'It was discovered in Mazatlán, Sinaloa, thus, it´s name.',
                ],
                'generalidades': [
                    'A robust toad with scratchy skin. It can measure up to almost 4 inches.',
                    'It lives in the lowland rainforest, tropical and subtropical dry broadleaf forests, and pine trees.',
                    'It eats beetles, ants and other insects.',
                ],
            },
            {
                'nombre_comun':'Common Boa, Boa Imperator, Central American Boa',
                'nombre_display':'Common boa',
                'nombre_cientifico':'Boa constrictor imperator',
                'conservacion':'Least concern',
                'route':'common-boa',
                'carpet_route':'boa-comun',
                'datos': [
                    'Lifespan of up to 25 to 30 years.',
                    'It is not poisonous.',
                    'It´s name says it all: it constricts and squeezes its prey to suffocate, then eat them. However, it cannot eat large preys. It seems that some Boas have died trying to eat possums.',
                ],
                'generalidades': [
                    'It measures 6.5 to 10 feet. Grey body with backside markings. Head has 3 dark stripes.',
                    'It is most active in the afternoon and evening. It is solitary.',
                ],
            },
            {
                'nombre_comun':'Mexican Beaded Lizard, Scorpion Lizard',
                'nombre_display':'Mexican/beaded lizard',
                'nombre_cientifico':'Heloderma horridum',
                'conservacion':'Least concern',
                'route':'mexican-beaded',
                'carpet_route':'escorpion-mexicano',
                'datos': [
                    'It is one of the two most poisonous lizzards that exist in the world.',
                    'It uses its venom as a defense, but does not inject it. Instead, it strains from its grooved teeth.',
                    'It is of great medical importance, since the toxins from its venum have potential medical uses.',
                ],
                'generalidades': [
                    'Saurian measuring about 16 inches, large head and body, and a short tail.',
                    'Lives on the Mexican Pacific coast, and is most active during early morning or sundown. It is slow-moving and quiet.',
                    'It feeds on eggs and small prey. They´re getting more and more scarce.',
                ],
            },
        ]
    },
    {
        /* GARDENS */
        'id':'gardens',
        'nombre':'GARDENS',
        'habitat':'land',
        'header_class':'jardines',
        'carpeta':'tierra',
        'description':'Amaze yourself with the wide variety of plants at the Aquarium; live creatures that give us oxygen, and fill your tour of the premises with joy and fresh air.',
        'preguntas': [
            {'pregunta':'What is vegetation?', 'respuesta':'It is the name given to the plant communities that coexist and develop in a place or region.'},
            {'pregunta':'How important are plants?', 'respuesta':'They allow us to have a pleasant environment, since with the support of their leaves, branches and stems, they keep humidity and regulate temperature. In addition, they capture carbon dioxide and produce oxygen.'},
            {'pregunta':'What is an endemic species?', 'respuesta':'Species (including plants) that live in a limited area. Within the gardens you will find plants that are only found in this region.'},
        ],
        'especies': [
            {
                'nombre_comun':'Alamos tree',
                'nombre_cientifico':'Albizia sinaloensis',
                'conservacion':'Not evaluated',
                'route':'alamos-tree',
                'carpet_route':'capiro',
                'datos': [
                    'It is an evergreen tree, which means it keeps its foliage year-around.',
                ],
                'generalidades': [
                    'This tree can grow up to 98 feet. Its leaves are distributed alternately, its bark has a yellow tone, and usually has many extended flowers. In Mexico, it is found in the states of Sinaloa and Sonora.',
                ],
            },
            {
                'nombre_comun':'Pacific coast mahogany',
                'nombre_display':'Pacific/coast mahogany',
                'nombre_cientifico':'Swietenia humilis',
                'conservacion':'Endangered',
                'route':'pacific-coast',
                'carpet_route':'venadillo',
                'datos': [
                    'It is esteemed for its wood, which is used for making fine furniture, musical instruments and for interior decoration.',
                ],
                'generalidades': [
                    'It grows up to 82 feet high and up to 3 feet in diameter. It starts blooming after 15 to 25 years, between the months of March and April.',
                ],
            },
            {
                'nombre_comun':'Guanacaste',
                'nombre_cientifico':'Enterolobium cyclocarpum',
                'conservacion':'Least concern',
                'route':'guanacaste',
                'carpet_route':'huanacaxtle',
                'datos': [
                    'It is of commercial importance due to its wood, for fine woodwork and woodcrafting. Its fruit is used as food for cattle, and traditionally for medicinal use.',
                ],
                'generalidades': [
                    'It is a large and striking tree that can measure up to 148 feet tall. This species is native to Mexico, Central America and Northern South America. It bears fruit during the months of May and June.',
                ],
            },
            {
                'nombre_comun':'Rosy trumpet tree',
                'nombre_display':'Rosy/trumpet tree',
                'nombre_cientifico':'Tabebuia rosea',
                'conservacion':'Least concern',
                'route':'rosy-trumpet',
                'carpet_route':'amapa',
                'datos': [
                    'Its leaves and bark are used to treat typhoid fever and parasites. Its beautiful flowers have a trumpet-like shape, and attract many different pollinators.',
                ],
                'generalidades': [
                    'It is a deciduous tree, meaning that it loses its leaves during the dry season. It usually grows to between 49 to 82 feet tall, and up to 98 feet. It blooms from February to June, and bears fruit between March and June.',
                ],
            },
            {
                'nombre_comun':'Royal poinciana',
                'nombre_cientifico':'Delonix regia',
                'conservacion':'Least concern',
                'route':'royal-poinciana',
                'carpet_route':'tabachin',
                'datos': [
                    'Its leaves and flowers are used in traditional medicine for some ethnic groups. It is considered a rapid-growth tree, since it grows from 3 to 6 feet a year.',
                ],
                'generalidades': [
                    'It is found in warm, humid environments and can measure from 26 to 39 feet tall, with eye-catching red, orange and yellow flowers.',
                ],
            },
            {
                'nombre_comun':'Manila tamarind',
                'nombre_cientifico':'Pithecellobium dulce',
                'conservacion':'Least concern',
                'route':'manila-tamarind',
                'carpet_route':'guamuchil',
                'datos': [
                    'It bears an edible fruit in a pod that tends to roll itself into a spiral form. It has also been used for reforestation and firewood purposes.',
                ],
                'generalidades': [
                    'Native of Mexico, Central America and South America. Fast-growing tree that grows up to 82 feet high.',
                ],
            },
            {
                'nombre_comun':'Guayacan',
                'nombre_cientifico':'Tabebuia guayacan',
                'conservacion':'Least concern',
                'route':'guayacan',
                'carpet_route':'guayacan',
                'datos': [
                    'It is held in high esteem by some ethnic groups for its medicinal uses.',
                ],
                'generalidades': [
                    'It is native to warmer environments in Northern Mexico; it has yellow flowers and can grow up to 49 feet high.',
                ],
            },
            /*
            {
                'nombre_comun':'Palma de chicle',
                'nombre_cientifico':'Dioon spinulosum',
                'conservacion':'En peligro',
                'route':'palma-chicle',
                'datos': [
                    'Su fruto es el más grande de las cícadas y uno de los más grande del grupo de las gimnospermas, llegando a pesar hasta 25 kg. Sus hojas se utilizan en ceremonias.',
                ],
                'generalidades': [
                    'De tronco robusto, llegan a crecer hasta 15metros de altura y agrupa sus hojas en forma de corona. Su distribución comprende desde el centro de México hasta Honduras.',
                ],
            },
            */
            {
                'nombre_comun':'Malabar Chestnut, Guiana Chestnut, Money Tree',
                'nombre_display':'Malabar chestnut',
                'nombre_cientifico':'Pachira aquatica',
                'conservacion':'Least concern',
                'route':'malabar-chestnut',
                'carpet_route':'apompo',
                'datos': [
                    'Its leaves, flowers and nuts are all edible.',
                    'Its flowers are among those of the largest trees in the world.',
                ],
                'generalidades': [
                    'It grows in tropical zones, native to Central and South America. It can grow to be up to 39 feet tall.',
                    'Its flowers open like banana peels, with long and slender yellowish-green or beige petals. It bears nuts that grow in green pods.',
                ],
            },
        ]
     },

    /* SEA */
    { 
        /* PUNTO PULMO ENGLISH */
        'id':'punto-pulmo',
        'nombre':'PUNTO PULMO',
        'habitat':'sea',
        'header_class':'punto-pulmo',
        'carpeta':'mar',
        'description':'A window into the fascinating world of live coral.',
        'preguntas': [
            {'pregunta': 'What is coral?', 'respuesta': 'They are marine animals that live in close relationship with microscopic algae, which enter the coral and provide them with food.'},
            {'pregunta': 'How many species of reef-building corals exist in the Sea of Cortez?', 'respuesta': 'There are 14 species of reef-building corals (hermatypic) and 11 of them are found in Cabo Pulmo National Park.'},
            {'pregunta': 'At what depths can we find corals forming reef reefs?', 'respuesta': 'It is common to find reefs between 0 to 12 m, because as depth increases there is less light, which is a source of energy for coral growth.'},
        ],
        'especies': [
            {
                'nombre_comun':'Convict Surgeonfish',
                'nombre_cientifico':'Acanthurus triostegus',
                'conservacion':'Least concern',
                'route':'convict-surgeonfish',
                'carpet_route':'cirujano-reo',
                'datos': [
                    'They can detect vibrations and posess a strong sense of smell and auditory perception.',
                    'They can “play dead” by exhibiting tonic immobility to avoid depredation.',
                    'Some of the largest bacteria in the world live in their intestines, and it is believed that these help them digest their food.',
                ],
                'generalidades': [
                    'It has an elongated oval shape, a head with a steep profile and eyes positioned high on its head. Of a white to light greenish-grey color, with 6 narrow lines on its head and body.',
                    'Adults live in lagoon and ocean reefs with a firm substrate, while juveniles are abundant in tide pools.',
                    'They feed on microalgae.',
                ],
            },
            {
                'nombre_comun':'Cortez Damselfish, Cortez Gregory',
                'nombre_display':'Cortez Damselfish',
                'nombre_cientifico':'Stegastes rectifraenum',
                'conservacion':'Least concern',
                'route':'cortez-damselfish',
                'carpet_route':'castaneta-azulada',
                'datos': [
                    'By constantly feeding on algae in its territory, it helps regulate its growth and improve the reef´s productivity.',
                    'It is an endemic fish, and one of the most abundant along the Mexican Pacific coast.',
                ],
                'generalidades': [
                    'Compressed, oval body. Adult´s body is dark brown and its head is pale. Juveniles are bright blue with a couple of neon blue lines on the top of their head. They have a black stain on their dorsal fin.',
                    'They live on shallow rocky reefs in the surf zone.',
                    'They are omnivores, eating a little bit of everything.',
                ],
            },
            {
                'nombre_comun':'Cortez Rainbow Wrasse',
                'nombre_display':'Cortez/Rainbow Wrasse',
                'nombre_cientifico':'Thalassoma lucasanum',
                'conservacion':'Least concern',
                'route':'cortez-rainbow-wrasse',
                'carpet_route':'arcoiris-cortes',
                'datos': [
                    'They practically disappear once the water temperature comes down to 80°F.',
                    'They have a unique way of moving up and down when they use their pectoral fins. Come and see them!',
                    'There are two types of males and two reproductive systems, and sometimes, females can transform themselves into males.',
                ],
                'generalidades': [
                    'Thin and elongated body. Both males and females have a striped body with a yellow and a greenish-blue band, one yellow and one red line, and a white belly.',
                    'Another type of male has a dark blue head, a wide yellow band behind its head, a blue tail, purple body and yellow pectoral fins.',
                    'It lives in aggregations in and around rocky and coral reefs, as well as in intertidal pools.',
                    'It feeds on plankton and small invertebrates.',
                ],
            },
            /*
            {
                'nombre_comun':'Gorgonias marinas',
                'nombre_cientifico':'Eugorgia sp.',
                'conservacion':'No evaluado',
                'route':'gorgonias-marinas',
                'datos': [
                    'Pertenecen a una familia de corales conocidos como "Abanicos de mar".',
                ],
                'generalidades': [
                    'Es un género de corales que se distribuye en el Océano Pacífico Oriental. Su cuerpo cuenta con',
                ],
            },
            */
            {
                'nombre_comun':'Orange Cup Coral',
                'nombre_display':'Orange/Cup Coral',
                'nombre_cientifico':'Tubastrea occinea',
                'conservacion':'Not evaluated',
                'route':'orange-cup-coral',
                'carpet_route':'coral',
                'datos': [
                    'This species can survive on artificial materials such as granite, cement and steel.',
                    'It is an invasive species. It extended throughout the eastern Atlantic Ocean in 60 years.',
                    'They can grow in complete darkness, as long as they can capture enough food.',
                ],
                'generalidades': [
                    'Its colonies are either orange, red, bright yellow, pinkish-red, or pink. Its polyps usually spread during the night.',
                    'These corals do not form reefs, but may live close to them, on rocks, and even in cold waters.',
                ],
            },
            {
                'nombre_comun':'Pavona gigantea',
                'nombre_cientifico':'Pavona gigantea',
                'conservacion':'Least concern',
                'route':'pavona-gigantea',
                'carpet_route':'pavona',
                'datos': [
                    'Its colonies are massive and play an important role in reef formation.',
                    'It is one of the most common corals on the Mexican Pacific coast.',
                ],
                'generalidades': [
                    'This coral is large, thick, encrusting, and is green, gray and brown in color. Its upper surface is almost flat, or with a varying degree of uneven waves, covered with large, distant and starry modules.',
                    'It lives on rocky seabeds in shallow, tropical waters.',
                    'It feeds on certain algae it associates with, and also captures some amount of plankton.',
                ],
            },
        ]
    },
    /* CORAL CITY */
    { 
        'id':'coral-city',
        'nombre':'CORAL CITY',
        'habitat':'sea',
        'header_class':'ciudad-coral',
        'carpeta':'mar',
        'description':'Reefs are the ocean´s metropolis. Delight in the colors, marine life and lights of the Coral City!',
        'preguntas': [
            {'pregunta': 'What is a coral?', 'respuesta': 'Corals are marine animals that live in close interaction with microscopic algae, that enter the corals and provide them with energy through photosynthesis.'},
            {'pregunta': 'How are reefs formed?', 'respuesta': 'Small, individual corals (polyps) form colonies that protect themselves with a calcium carbonate skeleton. All together, these structures constitute a coral reef.'},
            {'pregunta': 'What is the importance of coral reefs?', 'respuesta': 'They are highly diverse environments, meaning, they harbor a great variety of marine life. They serve as a shelter and feeding zone for different species.'},
        ],
        'especies': [
            /*
            {
                'nombre_comun':'Botete de aletas punteadas',
                'nombre_display':'Botete/de aletas punteadas',
                'nombre_cientifico':'Arothron meleagris',
                'conservacion':'Preocupación menor',
                'route':'botete-aletas',
                'datos': [
                    'Se inflan como mecanismo de defensa. Al igual que muchos botetes/globos, presentan una neurotoxina en su piel, vísceras y gónadas. Su distribución dentro del Pacífico Oriental comprende desde las costas de Guaymas Sonora hasta el Ecuador.',
                ],
                'generalidades': [
                    'Pez de cuerpo redondeado, de color negro uniforme cubierto con numerosas manchas blancas pequeñas. Vive en áreas de arrecifes rocosos y de coral. Se alimenta de coral, pequeños animales marinos, esponjas, algas y erizos de mar.',
                ],
            },
            
            {
                'nombre_comun':'Cirujano aleta amarilla',
                'nombre_display':'Cirujano/aleta amarilla',
                'nombre_cientifico':'Acanthurus xanthopterus',
                'conservacion':'Preocupación menor',
                'route':'cirujano-gris',
                'datos': [
                    'Es venenoso y es el más grande de los Peces Cirujano.',
                    'En aguas mexicanas tienen una distribución limitada, solo se encuentra en el extremo sur del Mar de Cortés entre La Paz y Cabo San Lucas, y a lo largo de la costa del continente desde Acapulco hasta Guatemala.',
                ],
                'generalidades': [
                    'Pez de color gris violáceo, aletas parcialmente amarillas.',
                    'Vive en varios hábitats de arrecifes, laderas de arena y lagunas. Los juveniles prefieren aguas costeras poco profundas, protegidas y turbias, mientras que los adultos prefieren áreas más profundas de bahías y lagunas.',
                    'Se alimentan durante el día de diatomeas, detritos de arena, algas filamentosas, hidroides y trozos de pescado.'
                ],
            },
            */
            {
                'nombre_comun':'Razor Surgeonfish',
                'nombre_cientifico':'Prionurus laticlavius',
                'conservacion':'Least concern',
                'route':'razor-surgeonfish',
                'carpet_route':'cirujano',
                'datos': [
                    'They generaly move in shoals and can submerge to a depth of up to 98 feet, which is uncommon for this type of fish. The are distributed from the Gulf of California to El Salvador, including the Revillagigedo Islands.',
                ],
                'generalidades': [
                    'It´s body has an oval, compressed shape, and is gray with small black specks and two dark stripes on its head. Its tail is yellow, and its mouth is small and protruding. It lives in coastal seabeds, close to reefs.',
                    'It feeds on benthic microalgae and macroalgae, as well as seaweeds.',
                ],
            },
            {
                'nombre_comun':'Starry Grouper, Flag Cabrilla',
                'nombre_display':'Starry Grouper',
                'nombre_cientifico':'Epinephelus labriformis',
                'conservacion':'Least concern',
                'route':'starry-grouper',
                'carpet_route':'mero',
                'datos': [
                    'It is a sequential hermaphrodite fish, meaning, it is first a female, then it transforms itself into a male.',
                ],
                'generalidades': [
                    'It´s a robust, reddish-brown fish, covered in white, irregular specks. It has a characteristic black marking on the base of its caudal fin, which runs onto its sides.',
                    'It lives in rocky zones of coastal waters.',
                    'During the day it feeds on small fish, and on crustaceans during the night.',
                ],
            },
            {
                'nombre_comun':'Clarion Angelfish',
                'nombre_cientifico':'Holacanthus clarionensis ',
                'conservacion':'Vulnerable',
                'route':'clarion-angelfish',
                'carpet_route':'angel',
                'datos': [
                    'It is endemic of the Revillagigedo and Clipperton islands, and is sometimes found in the Gulf of California.',
                    'It is the main cleaner fish for the Giant Oceanic Manta Ray (Manta birostris).',
                ],
                'generalidades': [
                    'It has a rectangular-shaped body. It is generally a brownish-orange color with a dark brown head, a bright orange stripe behind its head and an orange caudal fin. Juveniles are of a brownish-orange color, with thin blue lines on their head and at their sides.',
                    'It eats sea sponges, tunicates, hydrozoans, crustaceans, mollusks, plankton and algae.',
                    'It lives in rocky reefs. Juveniles are solitary and territorial, while adults move along the seabed in schools of up to 30 individuals.',
                ],
            },
            {
                'nombre_comun':'King Angelfish, Passer Angelfish',
                'nombre_display':'King Angelfish',
                'nombre_cientifico':'Holacanthus passer',
                'conservacion':'Least concern',
                'route':'king-angelfish',
                'carpet_route':'angel_real',
                'datos': [
                    'Females are more territorial and establish mating relationships with males.',
                    'Juveniles have been observed as cleaner fish for other fish.',
                ],
                'generalidades': [
                    'It has a rectangular body shape. Adults are greyish-dark blue with a slim white vertical stripe, yellowish-orange fins and an orange spot on the corner of their mouth. Juveniles are multicolored with vertical stripes.',
                    'It feeds on sea sponges, invertebrates, plankton and algae.',
                    'It lives in rocky and coral reefs.',
                ],
            },
            {
                'nombre_comun':'Ángel de Cortés',
                'nombre_cientifico':'Pomacanthus zonipectus',
                'conservacion':'Preocupación menor',
                'route':'angel-cortes',
                'carpet_route':'angel-cortes',
                'datos': [
                    'Los adultos suelen desplazarse en parejas o en agregaciones, mientras que los juveniles son territoriales y se desplazan de manera individual.',
                    'Suelen relacionarse con una sola pareja.',
                ],
                'generalidades': [
                    'Cuerpo alto y comprimido, con un hocico corto; boca pequeña con dientes en forma de cerdas. Su color es grisáceo con bandas negras y amarillas, cola color amarillo pálido. Los juveniles se distinguen por contar con bandas azules y amarillas en su cuerpo.',
                    'Se alimenta de corales suaves, estrellas de mar, pepinos, anémonas y esponjas.',
                ],
            },
            {
                'nombre_comun':'Mariposa rayada o Ídolo moro',
                'nombre_display':'Mariposa rayada',
                'nombre_cientifico':'Zanclus cornutus',
                'conservacion':'Preocupación menor',
                'route':'mariposa-rayado',
                'carpet_route':'mariposa-rayado',
                'datos': [
                    'Son peces activos que disfrutan pasar el día explorando su habitat. Por lo general son sociales con peces de otras especies.',
                ],
                'generalidades': [
                    'Tiene su cuerpo en forma de disco con una aleta dorsal alargada similar a un látigo. Su coloración es blanca con franjas de color negro, cuenta con 2 manchas color amarillo, una en la mitad trasera de cuerpo y otra cercana a su boca.',
                    'Vive asociado a los arrecifes de coral, donde se alimenta de algas, esponjas, krill o pequeños peces.',
                ],
            },
        ]
    },
    /* SEA STABLE */
    { 
        'id':'sea-stable',
        'nombre':'SEA STABLE',
        'habitat':'sea',
        'header_class':'establo-marino',
        'carpeta':'mar',
        'description':'You´re going to love the subtle cavalcade of these magnificent fish. Don´t miss the opportunity to see the Sea Horses in their stable.',
        'preguntas': [
            {'pregunta': 'What is a Seahorse?', 'respuesta': 'Seahorses are fish, that get their common name from the shape of their head.'},
            {'pregunta': 'Which is the Seahorse´s habitat?', 'respuesta': 'They live in warm waters, commonly associated with ocean reefs and seagrass meadows.'},
            {'pregunta': 'Which is the largest Seahorse species?', 'respuesta': 'The Pacific Seahorse (Hippocampus ingens) is one of the largest Seahorse species in the world. It can measure up to 12 inches long.'},
        ],
        'especies': [
            {
                'nombre_comun':'Blue-striped pipefish',
                'nombre_display':'Blue-striped/pipefish',
                'nombre_cientifico':'Doryrhamphus excisus',
                'conservacion':'Least concern',
                'route':'blue-striped-pipefish',
                'carpet_route':'pipa_chica',
                'datos': [
                    'It uses its tubular snout to ingest its food, similar to a Seahorse.',
                    'It is monogamous, and the male carries the eggs in an offspring pouch located under its tail.',
                ],
                'generalidades': [
                    'It has a long snout with a central, thorny crest. It´s of a reddish-brown color, with a blue band at the top of its body and another black one on the sides of its snout.',
                    'It is uncommon, and disguises itself well; it prefers to stay in cracks found in rocks and corals, and areas under fractured seabeds.',
                    'It feeds on small crustaceans and plankton.',
                ],
            },
            {
                'nombre_comun':'Pacific seahorse',
                'nombre_cientifico':'Hippocampus ingens',
                'conservacion':'Vulnerable ',
                'route':'pacific-seahorse',
                'carpet_route':'caballito',
                'datos': [
                    'It is one of the largest of almost 50 kown Seahorse species in the world.',
                    'It does not have teeth nor a stomach, and sucks-in its food, much like drinking from a straw.',
                    'The female deposits its eggs in the male´s incubator sac, where they remain until the little Seahorses are born.',
                ],
                'generalidades': [
                    'Its neck is curved, with its head almost at a right angle to its body, which is covered in bony rings.',
                    'It has an elongated snout with a crownlike structure on its head. Its color varies to camouflage itself in its environment.',
                    'It is most active during the night, and lives in coastal waters.',
                    'It feeds on zooplankton and crustaceans.',
                ],
            },
        ]
     },
     /* LOOKDOWNS */
     { 
        'id':'lookdowns',
        'nombre':'LOOKDOWNS',
        'habitat':'sea',
        'header_class':'espejos',
        'carpeta':'mar',
        'description':'A great water column streams down the heart of the Aquarium. In it, live Lookdowns offer us a beautiful, brightly synchronized spectacle.',
        'preguntas': [
            {'pregunta': 'Why are they commonly called Mirror fish?', 'respuesta': 'Pacific Lookdowns are commonly called Mirror fish in Spanish, because of the silvery reflection of their scales. You´ll be surprised at how brilliantly they shine.'},
            {'pregunta': 'What do Lookdowns eat?', 'respuesta': 'Lookdowns are carnivores that feed on small octopus, fish, shrimp and squid.'},
            {'pregunta': 'Where can we find Lookdowns?', 'respuesta': 'The Selene brevoortii species is distributed from the Baja California and Sea of Cortez coasts up to Ecuador.'},
        ],
        'especies': [
            {
                'nombre_comun':'Pacific Lookdown, Hairfin Lookdown, Mexican Lookdown',
                'nombre_display':'Pacific lookdown',
                'nombre_cientifico':'Selene brevoortii',
                'conservacion':'Least concern',
                'route':'pacific-lookdown',
                'carpet_route':'pez-espejo',
                'datos': [
                    'Its silver color (due to guanine crystals) gives its body a mirror effect, that it can control to either tone down its reflection or to strengthen it, to improve its camouflage.',
                ],
                'generalidades': [
                    'It is a fish of coastal waters. Its silver body is of a compressed, pentagonal shape. It can measure up to 10 inches in length.',
                    'It lives in shallow coastal waters, in small banks close to the seabed.',
                    'It feeds on small squids, shrimp, bristle worms and small fish.',
                ],
            },
        ]
    },
    /* OCEAN */
    { 
        'id':'ocean',
        'nombre':'OCEAN',
        'habitat':'sea',
        'header_class':'oceanica',
        'carpeta':'mar',
        'description':'Reach into the heart of the sea: live the incredible beauty and majesty of sea life. This is the largest habitat of the Aquarium, with a window measuring over 98 feet long that will leave you breathless!',
        'preguntas': [
            {'pregunta': 'Why is the ocean important?', 'respuesta': 'The ocean and its life forms provide the framework for this planet earth, and allow life on earth to be inhabitable.'},
            {'pregunta': 'What are Pelagic fish species?', 'respuesta': 'They are fish species that are found swimming freely in the ocean, and usually don´t remain close to the coast.'},
            {'pregunta': 'How old is the Sea of Cortez?', 'respuesta': 'The magnificent Sea of Cortez originated approximately 5 to 6 million years ago, and it is the youngest sea in the world.'},
        ],
        'especies': [
            {
                'nombre_comun':'Blacktip shark',
                'nombre_cientifico':'Carcharhinus limbatus',
                'conservacion':'Near threatened',
                'route':'blacktip-shark',
                'carpet_route':'tiburon-puntas-negras',
                'datos': [
                    'DNA evidence was found in 2008, proving that a female fertilizes its eggs without male input.',
                    'They jump over the water, twirling, and falling on their backs, sometimes hitting schools of fish as a feeding strategy.',
                ],
                'generalidades': [
                    'A shark with a somewhat long snout with a pointy end, and small eyes. It measures between 4 and 6 feet long, and males are larger than females.',
                    'They live both on the coast and in the open sea, throughout the entire planet.',
                    'It actively hunts fish, small sharks, rays, cephalopods and crustaceans.',
                ],
            },
            {
                'nombre_comun':'Bignose shark',
                'nombre_cientifico':'Carcharhinus altimus',
                'conservacion':'Near threatened',
                'route':'bignose-shark',
                'carpet_route':'tiburon-colotrado',
                'datos': [
                    'It swims in deep waters during the day, and moves up closer to the surface during the night.',
                    'It can measure up to 10 feet long, and live up to 21 years.',
                ],
                'generalidades': [
                    'Of a robust body with a long, wide, and slightly pointy snout. Its eyes are round and its body is grey, turning white towards its ventral side.',
                    'It feeds on fish, rays and common cuttlefish, but can also eat other sharks.',
                    'It usually lingers in uneven, fractured seabeds.',
                ],
            },
            {
                'nombre_comun':'Sandbar shark',
                'nombre_cientifico':'Carcharinus plumbeus',
                'conservacion':'Vulnerable',
                'route':'sandbar-shark',
                'carpet_route':'tiburon-trozo',
                'datos': [
                    'Adult females usually develop a thicker skin layer than males. This thicker skin protects them during their mating rituals, and from the difficult conditions of the rocky and coral environments where they live.',
                ],
                'generalidades': [
                    'It has a robust body with a rounded and small nose. It is greyish-brown, and white on its underside.',
                    'It is found both on the coast and in the open sea, more frequently in bays, river mouths and ports, avoiding sandy beaches, coral reefs and rocky seabeds.',
                    'It feeds on fish, small shark, octopus, squid, shrimp, rays, sea snails and sea slugs. Juveniles prefer blue crabs and shrimp.',
                ],
            },
        ]
    },
    /* MIGRATION */
    { 
        'id':'migration',
        'nombre':'MIGRATION',
        'habitat':'sea',
        'header_class':'migracion',
        'carpeta':'mar',
        'description':'Witness the incredible journeys of travelers large and small, that arrive at the Sea of Cortez.',
        'preguntas': [
            {'pregunta': 'What is migration?', 'respuesta': 'It is the seasonal movement of a species from one place to another. It can occur for different motives, among them, the search for shelter, food, and mating reasons.'},
            {'pregunta': 'What qualities make the Sea of Cortez ideal for the migration of species?', 'respuesta': 'Periodically, the waters in the Sea of Cortez become highly rich in nutrients that are basic for the nutrition of fish of all sizes. Likewise, some of its bays and islands are safe zones for many fauna species.'},
            {'pregunta': 'What migratory marine species can we find in the Sea of Cortez?', 'respuesta': 'Among the migratory fauna of the Sea of Cortez are: The Gray Whale, Whale Shark, Olive Ridley Sea Turtle, Green Sea Turtle, Hawksbill Sea Turtle, Humboldt Squid, Giant Squid and Snowy Plover.'},
        ],
        'especies': [
            {
                'nombre_comun':'Colorado snapper',
                'nombre_cientifico':'Lutjanus colorado',
                'conservacion':'Least concern',
                'route':'colorado-snapper',
                'carpet_route':'pargo-rojo',
                'datos': [
                    'Its fertilized eggs are pelagic, spherical, transparent, and with one sole drop of oil.',
                    'The quantity and diversity of the Colorado Snapper´s prey increase in the Summer.',
                ],
                'generalidades': [
                    'Elongated, dark red body that gradually turns pink towards its underside; its fins are dark red.',
                    'Adults live in coastal reef zones on hard seabeds.',
                    'Its diet consists in invertebrates and fish.',
                ],
            },
            {
                'nombre_comun':'Spotted rose snapper',
                'nombre_display':'Spotted rose/snapper',
                'nombre_cientifico':'Lutjanus guttatus',
                'conservacion':'Least concern',
                'route':'spotted-rose-snapper',
                'carpet_route':'pargo-flamenco',
                'datos': [
                    'The longest specimen found measured 2.62 feet and the heaviest weighed 2.86 pounds.',
                ],
                'generalidades': [
                    'It is of a pale red or pink, to a yellowish-silver color, with greenish-gold to brown stripes. It has a distinctive black or grayish mark on its backside.',
                    'Adults are found over hard seabeds in coastal reef zones, and juveniles live in estuaries and river mouths.',
                    'It feeds on invertebrates and fish.',
                ],
            },
            {
                'nombre_comun':'Pacific Dog Snapper, Pacific Cubera Snapper',
                'nombre_display':'Pacific/dog snapper',
                'nombre_cientifico':'Lutjanus novemfasciatus',
                'conservacion':'Least concern',
                'route':'pacific-dog-snapper',
                'carpet_route':'pargo-prieto',
                'datos': [
                    'It is the largest Snapper on the Pacific.',
                ],
                'generalidades': [
                    'Its snout is pointy. Its backside and sides are of a dark olive brown to a copper red, with ash brown and silvery-white stripes on its abdomen.',
                    'Adults live in rocky reefs; juveniles live in estuaries with mangroves and river mouths.',
                    'It feeds on large invertebrates and fish.',
                ],
            },
        ]
    },
    /* THE GROTTO */
    {
        'id':'the-grotto',
        'nombre':'THE GROTTO',
        'habitat':'sea',
        'header_class':'la-cueva',
        'carpeta':'mar',
        'description':'Young and old alike can catch an up-close glimpse at the fish in the grotto, as you peek into this Habitat´s capsule and take a fun picture while you´re at it!',
        'preguntas': [
            {'pregunta': 'What are Morays?', 'respuesta': 'They are elongated fish with nocturnal habits, that prefer to rest during the day among rocky crevices and in grottos.'},
            {'pregunta': 'Are they dangerous?', 'respuesta': 'Contrary to what most people think, they are peaceful fish that prefer to remain sheltered, and only attack when they feel threatened. That is why we should always respect their space.'},
            {'pregunta': 'What do Morays eat?', 'respuesta': 'Their favorite foods are crustaceans and smaller fish. They don´t mind feeding at night, since they have an excellent sense of smell that helps them find their food.'},
        ],
        'especies': [
            {
                'nombre_comun':'Spotted Snake Eel, Spotted Tiger Snake',
                'nombre_display':'Spotted/snake eel',
                'nombre_cientifico':'Myrichthys tigrinus',
                'conservacion':'Least concern',
                'route':'spotted-snake-eel',
                'carpet_route':'tieso-tigre',
                'datos': [
                    'It has four nostrils: the first two, external, are tubular in shape and are very useful to smell its prey.',
                    'It could grow up to 2.5 feet in length.',
                ],
                'generalidades': [
                    'Tubular, elongated, beige-colored body with oval black spots. Spotted throat and spine. Its tail measures over half of its body length. It does not have scales.',
                    'It feeds on shrimp, crabs, bristle worms, fish, and mollusks such as octopus and squid.',
                    'It lives in sandy and muddy seabeds, and is commonly seen seeking its prey under rocks or in crevices.',
                ],
            },
            {
                'nombre_comun':'Panamic Green Moray Eel',
                'nombre_display':'Panamic green/moray eel',
                'nombre_cientifico':'Gymnothorax castaneus',
                'conservacion':'Least concern',
                'route':'panamic-green',
                'carpet_route':'morena-verde',
                'datos': [
                    'Its sight isn´t very good, but it has an excellent sense of smell that helps it locate and ambush its prey.',
                    'It is covered in thick, yellow mucus, which protects it against the rocks it lives among.',
                ],
                'generalidades': [
                    'It has an elongated body, that narrows towards a rounded tail. It can be green or brown in color. It has a large head and small eyes.',
                    'Its anal and dorsal fins are covered in skin and are fused with its tail, which gives it the appearance of a ribbon or snake. It can measure up to 5 feet in length.',
                    'It feeds on fish and crustaceans.',
                ],
            },
            {
                'nombre_comun':'Masked Moray',
                'nombre_cientifico':'Gymnothorax panamensis',
                'conservacion':'Least concern',
                'route':'masked-moray',
                'carpet_route':'morena-enmascarada',
                'datos': [
                    'It opens and closes its mouth constantly to breathe.',
                    'Its form, as well as its lack of fins or scales, make it agile enough to move rapidly through rocky crevices.',
                    'Morays have a 400% higher survival rate than any other fish, because they live in hiding among the rocks.',
                ],
                'generalidades': [
                    'It has a large head, short snout and pointy teeth. Its head is brown or yellowish-brown. Its round eyes are framed in a large, dark-brown ring, and the pores around its jaw are white.',
                    'It lives in rocky seabeds rich in crevices, and in reef habitats.',
                    'It feeds on fish and crustaceans such as crabs, lobsters and shrimp.',
                ],
            },
        ]
     },
    /* JELLYFISH HALL */
    {
        'id':'jellyfish-hall',
        'nombre':'JELLYFISH HALL',
        'habitat':'sea',
        'header_class':'salon-de-las-medusas',
        'carpeta':'mar',
        'description':'Come and let your imagination run free while watching the mesmerizing jellyfish, in a unique and magical environment we´ve created for them and for you.',
        'preguntas': [
            {'pregunta': 'What is a jellyfish?', 'respuesta': 'They are invertebrate animals (which have no bones), belonging to the group of cnidarians such as corals and anemones. They are known for their abundant tentacles, which they use to defend themselves and capture prey.'},
            {'pregunta': 'How do they move?', 'respuesta': 'The characteristic swim of jellyfish is due to their ability to tense their bodies to propel themselves through the water, and they also tend to move by taking advantage of ocean currents.'},
            {'pregunta': 'How long have jellyfish been around?', 'respuesta': 'These amazing animals have been around for more than 600 million years, long before the dinosaurs, so they are known as living fossils.'},
        ],
        'especies': [
            {
                'nombre_comun':'Moon Jellyfish',
                'nombre_cientifico':'Aurelia aurita',
                'conservacion':'Not evaluated',
                'route':'moon-jellyfish',
                'carpet_route':'medusa-luna',
                'datos': [
                    'During its first years of life, it is a polyp on seabeds and rocks. Then it turns into a moving jellyfish.',
                    'It can become an invasive species when it reproduces in an exaggerated manner, due to the water temperature or pollution.',
                    'A neon protein was developed from this jellyfish species, which is used in biotechnological research.',
                ],
                'generalidades': [
                    'It is a marine invertebrate that, in its stage as a free-swimming jellyfish, has a bell-shaped body that has hanging tentacles with stinging cells.',
                    'If feeds on plankton, mollusks, fish eggs, small fish and smaller jellyfish.',
                ],
            },
            {
                'nombre_comun':'Cannonball Jellyfish',
                'nombre_cientifico':'Stomolophus meleagris',
                'conservacion':'Not evaluated',
                'route':'cannonball-jellyfish',
                'carpet_route':'medusa-bola-canon',
                'datos': [
                    'It has light sensory structures (Rophalia and Statocysts) that allows it to perceive if it is approaching the water´s surface or the depths of the sea.',
                    'When if feels threatened, it secretes a toxic mucus that harms small fish and drives predators away.',
                    'It is often caught in Northern Sinaloa.',
                ],
                'generalidades': [
                    'This Jellyfish can be blue or grey, in Pacific waters. It has 16 white, short and branched oral arms.',
                    'It feeds on fish larvae, crustaceans, mollusks and fish eggs.',
                ],
            },
            {
                'nombre_comun':'Purple-striped Jelly',
                'nombre_display':'Purple-striped/Jelly',
                'nombre_cientifico':'Chrysaora colorata',
                'conservacion':'Not evaluated',
                'route':'purple-striped-jelly',
                'carpet_route':'medusa-rayas-moradas',
                'datos': [
                    'Cancer crab juveniles associate with this Jellyfish, since they feed on the parasites that harm it, and sometimes even live in it.',
                    'The Jellyfish´s bell pulsates to impulse its movement in short distances. But to further advance, it takes advantage of marine currents.',
                ],
                'generalidades': [
                    'Adults are silvery-white, with dark purple stripes. Juvenile Jellyfish are pink, with dark red tentacles. All have four spiral-shaped oral arms.',
                    'It can grow to be a little over 3 feet in diameter.',
                    'It feeds on zooplankton.',
                ],
            },
        ]
    },
    /* SHOALS */
    {
        'id':'shoals',
        'nombre':'SHOALS',
        'habitat':'sea',
        'header_class':'cardumen',
        'carpeta':'mar',
        'description':'Don´t miss-out on the sensation of having a whirlwind of fish above your head, a vortex of marine life!',
        'preguntas': [
            {'pregunta': 'What is a shoal?', 'respuesta': 'As we know them, they are groups of fish that swim and move together.'},
            {'pregunta': 'Why are shoals formed?', 'respuesta': 'The benefits of forming a group include: to defend theselves from predators, and to help find food and mating partners.'},
            {'pregunta': 'How do shoals find their way?', 'respuesta': 'Besides counting on their sense of sight, they also have a sense in the form of an organ called Lateral Line, which allows them to detect changes in water pressure, vibrations, and movement in their surroundings like when a fish changes course or direction.'},
        ],
        'especies': [
            {
                'nombre_comun':'Californian Pilchard',
                'nombre_display':'Californian/Pilchard',
                'nombre_cientifico':'Sardinops sagax',
                'conservacion':'Least concern',
                'route':'californian-pilchard',
                'carpet_route':'sardina-california',
                'datos': [
                    'They form huge shoals of hundreds of billions of individuals. The fast and coordinated movement of these shoals is a way to escape their predators.',
                    'Pilchards are migratory fish, and can travel distances of up to 620 miles a year, between its feeding and spawning habitats.',
                    'They are a very important fishing resource in Mexico.',
                ],
                'generalidades': [
                    'The Californian Pilchard has a thin, cilindrical body, of approximately 8 inches. Its backside is a greenish-blue and its sides are white, with 1 to 3 series of dark spots.',
                    'It feeds on plankton.',
                ],
            },
        ]
    },
    /* THE WINDOW */
    {
        'id':'the-window',
        'nombre':'THE WINDOW',
        'habitat':'sea',
        'header_class':'la-ventana',
        'carpeta':'mar',
        'description':'Take one last peek at the great Ocean Habitat through our Window, and open-up to ideas on how we can contribute to the conservation of our oceans.',
        'preguntas': [
            {'pregunta': 'How important is the Sea of Cortez?', 'respuesta': 'The Sea of Cortez or Gulf of California is an area that, due to its climate and availability of resources, has become the preferred place for many animals to feed and reproduce. The explorer Jacques Cousteau named it "the aquarium of the world".'},
            {'pregunta': 'Why do we seek the conservation of our oceans?', 'respuesta': 'Many conservation programs have focused on the oceans because they are our main source of oxygen, help regulate the climate and provide us with food.'},
            {'pregunta': 'What can we do at home to take care of our seas?', 'respuesta': 'We can all help to conserve these important places, with actions such as reducing the use of plastics, reducing the use of electricity and avoiding leaving trash behind when we visit the beach. Inside the Great Aquarium you can learn more about how we can work together to have a healthier, more vibrant ocean.'},
        ],
        'especies': [
            {
                'nombre_comun':'Scalloped Hammerhead',
                'nombre_display':'Scalloped/Hammerhead',
                'nombre_cientifico':'Sphyrna lewini',
                'conservacion':'Critically endangered',
                'route':'scalloped-hammerhead',
                'carpet_route':'tiburon-martillo',
                'datos': [
                    'It has been filmed with a camera outfitted with bait, at a depth of 1700 ft.',
                    'It forms large schools of small, migrant fish that move during the Summer in certain zones. Permanent resident populations also exist.',
                ],
                'generalidades': [
                    'A large-bodied and moderately-thin shark; its wide head has its distinctive “hammer”. It is of a light grey or grayish-brown color on top, and white on its underside.',
                    'It feeds mainly on fish, squid and octopus, and also on lobster, shrimp, crabs, other sharks and rays.',
                ],
            },
            {
                'nombre_comun':'Roosterfish',
                'nombre_cientifico':'Nematistius pectoralis',
                'conservacion':'Not evaluated',
                'route':'roosterfish',
                'carpet_route':'pez-gallo',
                'datos': [
                    'It is highly sought after in sport fishing. The largest ever caught was 1.63m and the heaviest was almost 52kg.',
                ],
                'generalidades': [
                    'Elongated body and pointed head. First dorsal fin has 7 very long spines that fold into a groove when the fin is lowered. It is bluish-gray with silver highlights and black bars.',
                    'It lives in shallow coastal areas, likes sandy shores and forms schools of fish.',
                    'Eats small fish.',
                ],
            },
            {
                'nombre_comun':'Common, silver or bull pompano',
                'nombre_display':'Common Pompano',
                'nombre_cientifico':'Trachinotus kennedyi',
                'conservacion':'Least concern',
                'route':'common-pompano',
                'carpet_route':'pampano-comun',
                'datos': [
                    'It has been caught near river mouths and sandy bottoms.',
                    'Although it was discovered since the late 1800s, very little is known about it.',
                    'Trachinotus means rough back.',
                ],
                'generalidades': [
                    'Short body, small mouth, prominent nape; head and upper body dark, sometimes golden or slightly tan, silver belly. It has a large black spot on the pectoral fin.',
                    'It inhabits shallow shallow waters and juveniles in estuaries.',
                    'It feeds on mollusks, crustaceans and small fish.',
                ],
            },
        ]
    },
];